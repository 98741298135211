import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import HomeCoverSlidePicture1 from '../../../assets/images/home_cover_slide_picture1.png';

import HomeCoverSlideIcon1 from '../../../assets/images/ip_logo_icon.png';
import HomeCoverSlideline from '../../../assets/images/home_cover_slide_line.png';

import './HomeCover.css';

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

const HomeCover = () => {

    var TxtType = function(el, toRotate, period) {
        this.toRotate = toRotate;
        this.el = el;
        this.loopNum = 0;
        this.period = parseInt(period, 10) || 200;
        this.txt = '';
        this.tick();
        this.isDeleting = false;
    };

    TxtType.prototype.tick = function() {
        var i = this.loopNum % this.toRotate.length;
        var fullTxt = this.toRotate[i];

        if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

        var that = this;
        var delta = 200 - Math.random() * 100;

        if (this.isDeleting) { delta /= 2; }

        if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
        } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 200;
        }

        setTimeout(function() {
        that.tick();
        }, delta);
    };

    window.onload = function() {
        var elements = document.getElementsByClassName('typewrite');
        for (var i=0; i<elements.length; i++) {
            var toRotate = elements[i].getAttribute('data-type');
            var period = elements[i].getAttribute('data-period');
            if (toRotate) {
              new TxtType(elements[i], JSON.parse(toRotate), period);
            }
        }
        // INJECT CSS
        var css = document.createElement("style");
        css.type = "text/css";
        css.innerHTML = ".typewrite > .wrap { border-right: 0.15em solid #6381a8; margin-left:5px}";
        document.body.appendChild(css);
    };


	return (
		
        <div className="home-cover">
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={8.3}>
                        <div className='font-bold fs-42 text-primary pt-5'>გადახდები</div>
                        <div className='font-bold fs-42 mb-4 text-secondary'>ერთ სივრცეში</div>
                        <div className='home-cover-hr'></div>
                        <div className="typewrite fs-18 font-bold-p text-secondary" 
                            data-period="200" 
                            data-type='[ 
                                "დარეგისტრირდი და ისარგებლე სხვადასხვა სერვისებით", 
                                "გადაიხადე ინტერნეტი,ტელეფონი, ტელევიზია მომენტალურად", 
                                "ისარგებლე პირადი კაბინეტით და მართე შენი გადახდები"
                            ]'
                        >
                            <span className="wrap"></span>
                        </div>
                    </Grid>
                    {/* <Grid item xs={4.3}>
                    </Grid> */}
                    <Grid item xs={3.7}>
                        <div className='home-cover-slide'>
                            <AliceCarousel 
                                autoPlay 
                                autoPlayInterval="3000"   
                                fadeOutAnimation={true} 
                                animationType="fadeout"
                                disableButtonsControls
                                disableDotsControls
                            >
                                <img src={HomeCoverSlidePicture1} className="home-cover-slide-picture"/>
                                <img src={HomeCoverSlidePicture1} className="home-cover-slide-picture"/>
                                <img src={HomeCoverSlidePicture1} className="home-cover-slide-picture"/>
                                <img src={HomeCoverSlidePicture1} className="home-cover-slide-picture"/>
                            </AliceCarousel>
                            {/* <img src={HomeCoverSlidePicture1} className='home-cover-slide-picture'/> */}
                            <div className='home-cover-slide-icon'>
                                <img src={HomeCoverSlideIcon1} className='home-cover-slide-icon-icon'/>
                            </div>
                            <div className='home-cover-slide-line'>
                                <img src={HomeCoverSlideline} className='rotate'/>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
		
	);
};

export default HomeCover;
