import React, { useEffect } from 'react';

import {
	Grid,
	Container,
	Breadcrumbs,
	Link,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Checkbox,
	TextField,
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import { IconCreditCard } from '@tabler/icons';
import Footer from '../Layouts/Footer';
import Header from '../Layouts/Header';
import BodyFrame from '../Layouts/BodyFrame';

const Cart = (prop) => {
	return (
		<>
			<Header />
			<BodyFrame />
			<div className="page">
				<div className="breadcrumbs">
					<Container>
						<Breadcrumbs separator="›" aria-label="breadcrumb">
							<Link
								underline="hover"
								separator="-"
								key="1"
								color="inherit"
								href="/"
							>
								<HomeIcon fontSize="inherit" />
							</Link>
							,
							<Typography key="2" color="text.primary">
								კალათი
							</Typography>
						</Breadcrumbs>
					</Container>
				</div>

				<div className="cart">
					<Container>
						<Grid container spacing={2} align="left">
							<Grid item xs={12}>
								<div className="product">
									<table>
										<tr className="table-header">
											<td width="3%" className="font">
												<Checkbox defaultChecked sx={{ m: 0, p: 0 }} />
											</td>
											<td width="" className="font">
												კომპანია
											</td>
											<td width="" className="font">
												სერვისი
											</td>
											<td width="" className="font">
												დეტალები
											</td>
											<td align="center" width="8%" className="font">
												პაკეტი
											</td>
											<td align="center" width="8%" className="font">
												ფასი
											</td>
											<td align="center" width="8%" className="font">
												ბალანსი
											</td>
											<td align="center" width="10%" className="font">
												თანხა
											</td>
										</tr>
										<tr>
											<td>
												{' '}
												<Checkbox defaultChecked sx={{ m: 0, p: 0 }} />
											</td>
											<td>ინტერფეი სერვს ჯგუფი </td>
											<td> </td>
											<td>
												თბილისი, ვაკე-საბურთალოს, მე4 მიკრო რაიონი მე5 შესახვევი
												კვარტალი 2 ბინა 4/6 555555555, 555555555, 555555555,
												555555555
											</td>
											<td align="center">Silver</td>
											<td align="center">50 ₾</td>
											<td align="center">0 ₾</td>
											<td>
												<TextField
													id="outlined-basic"
													label="50 ₾"
													fullWidth
													variant="outlined"
													size="small"
													textField
													className="inputRounded"
												/>
											</td>
										</tr>
										<tr>
											<td>
												{' '}
												<Checkbox defaultChecked sx={{ m: 0, p: 0 }} />
											</td>
											<td>ტელეკომ 1 </td>
											<td>ტელეფონი </td>
											<td>ქუთაისი </td>
											<td align="center">Gold</td>
											<td align="center">150 ₾</td>
											<td align="center">50 ₾</td>
											<td>
												<TextField
													id="outlined-basic"
													label="100 ₾"
													fullWidth
													variant="outlined"
													size="small"
													textField
													className="inputRounded"
												/>
											</td>
										</tr>
										<tr>
											<td>
												{' '}
												<Checkbox defaultChecked sx={{ m: 0, p: 0 }} />
											</td>
											<td>დელტაკომი </td>
											<td>ტელევიზია </td>
											<td>ქუთაისი </td>
											<td align="center">Silver</td>
											<td align="center">50 ₾</td>
											<td align="center">50 ₾</td>
											<td>
												<TextField
													id="outlined-basic"
													label="0 ₾"
													fullWidth
													variant="outlined"
													size="small"
													textField
													className="inputRounded"
												/>
											</td>
										</tr>
										<tr>
											<td>
												{' '}
												<Checkbox defaultChecked sx={{ m: 0, p: 0 }} />
											</td>
											<td>ტელეკომ 1 </td>
											<td>ინსტალაცია - ინტერნეტი </td>
											<td>ქუთაისი </td>
											<td align="center">-</td>
											<td align="center">50 ₾</td>
											<td align="center">0 ₾</td>
											<td>
												<TextField
													id="outlined-basic"
													label="50 ₾"
													fullWidth
													variant="outlined"
													size="small"
													textField
													className="inputRounded"
												/>
											</td>
										</tr>
										<tr>
											<td>
												{' '}
												<Checkbox defaultChecked sx={{ m: 0, p: 0 }} />
											</td>
											<td>ტელეკომ 1 </td>
											<td>ინტერნეტი </td>
											<td>ქუთაისი </td>
											<td align="center">Silver</td>
											<td align="center">50 ₾</td>
											<td align="center">0 ₾</td>
											<td>
												<TextField
													id="outlined-basic"
													label="50 ₾"
													fullWidth
													variant="outlined"
													size="small"
													textField
													className="inputRounded"
												/>
											</td>
										</tr>
										<tr>
											<td>
												{' '}
												<Checkbox defaultChecked sx={{ m: 0, p: 0 }} />
											</td>
											<td>ტელეკომ 1 </td>
											<td>ტელეფონი </td>
											<td>ქუთაისი </td>
											<td align="center">Gold</td>
											<td align="center">150 ₾</td>
											<td align="center">50 ₾</td>
											<td>
												<TextField
													id="outlined-basic"
													label="100 ₾"
													fullWidth
													variant="outlined"
													size="small"
													textField
													className="inputRounded"
												/>
											</td>
										</tr>
										<tr>
											<td>
												{' '}
												<Checkbox defaultChecked sx={{ m: 0, p: 0 }} />
											</td>
											<td>დელტაკომი </td>
											<td>ტელევიზია </td>
											<td>ქუთაისი </td>
											<td align="center">Silver</td>
											<td align="center">50 ₾</td>
											<td align="center">50 ₾</td>
											<td>
												<TextField
													id="outlined-basic"
													label="0 ₾"
													fullWidth
													variant="outlined"
													size="small"
													textField
													className="inputRounded"
												/>
											</td>
										</tr>
										<tr>
											<td>
												{' '}
												<Checkbox defaultChecked sx={{ m: 0, p: 0 }} />
											</td>
											<td>ტელეკომ 1 </td>
											<td>ინსტალაცია - ინტერნეტი </td>
											<td>ქუთაისი </td>
											<td align="center">-</td>
											<td align="center">50 ₾</td>
											<td align="center">0 ₾</td>
											<td>
												<TextField
													id="outlined-basic"
													label="50 ₾"
													fullWidth
													variant="outlined"
													size="small"
													textField
													className="inputRounded"
												/>
											</td>
										</tr>
									</table>
								</div>
							</Grid>
							<Grid container spacing={2} align="left">
								<Grid item xs={8}></Grid>
								<Grid item xs={4}>
									<br></br>
									<div className="price">
										<div className="item">
											<FormControl fullWidth size="small">
												<InputLabel id="demo-simple-select-label">
													გადახდის მეთოდი
												</InputLabel>
												<Select
													labelId="demo-select-small"
													id="demo-select-small"
													label="გადახდის მეთოდი"
												>
													<MenuItem>ქართული ბარათით</MenuItem>
													<MenuItem>უცხოური ბარათით</MenuItem>
												</Select>
											</FormControl>
										</div>
										<div className="item">
											<Grid container spacing={2} align="left">
												<Grid item xs={9}>
													<div>სერვისების რაოდენობა:</div>
												</Grid>
												<Grid item xs={3} align="right">
													<div>5</div>
												</Grid>
											</Grid>
										</div>
										<div className="item">
											<Grid container spacing={2} align="left">
												<Grid item xs={9}>
													<div>გადასახდელი თანხა:</div>
												</Grid>
												<Grid item xs={3} align="right">
													<div>200 ₾</div>
												</Grid>
											</Grid>
										</div>
										<div className="item">
											<Grid container spacing={2} align="left">
												<Grid item xs={9}>
													<div>საკომისიო:</div>
												</Grid>
												<Grid item xs={3} align="right">
													<div>30 ₾</div>
												</Grid>
											</Grid>
										</div>
										<div className="item">
											<Grid container spacing={2} align="left">
												<Grid item xs={10}>
													<div>სულ გადასახდელი თანხა: </div>
												</Grid>
												<Grid item xs={2} align="right">
													<div>230 ₾</div>
												</Grid>
											</Grid>
										</div>
										<div>
											<Button
												className={'button'}
												fullWidth
												endIcon={<IconCreditCard />}
											>
												გადახდა
											</Button>
										</div>
									</div>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Cart;
