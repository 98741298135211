import React from 'react';
import { useParams } from 'react-router-dom';

import BodyFrame from '../Layouts/BodyFrame';
import Footer from '../Layouts/Footer';
import Header from '../Layouts/Header';
import UserMessages from './UserMessages';

const Messages = () => {

	const params = useParams();
	const pageID = (params?.id === 'outbox') ? 'out' : 'in';

	return (
		<>
			<Header />
			<BodyFrame />
			<div className={'page'}>
				<UserMessages direction={pageID} />
			</div>
			<Footer />
		</>
	)
}

export default Messages;