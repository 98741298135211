import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { IconHome2 } from '@tabler/icons';

export default function Navlink(props) {

	const links = props.links;

	return (
		<div className="breadcrumbs">
			<Container>
				<Breadcrumbs separator="›" aria-label="breadcrumb">
					<Link
						underline="hover"
						separator="-"
						key="1"
						href="/"
					>
						<IconHome2 className='breadcrumbs-home' width='16px' />
					</Link>
					,
					<Typography key="2" color="text.primary">
						<Link
							underline="hover"
							separator="-"
							key="1"
							href={links[0].link ? links[0].link : ""}
						>
							{links[0].title}
						</Link>
					</Typography>
					,
					{links[1] &&
						<Typography key="3" color="text.primary">
							{links[1].title}
						</Typography>
					}
				</Breadcrumbs>
			</Container>
		</div>
	);
}