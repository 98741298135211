import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import LinearProgress from '@mui/material/LinearProgress';

import ApiService from '../../../API/ApiService';
import { useFetching } from '../../../hooks/useFetching';

import NumberFormat from 'react-number-format';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';

import LoginIcon from '@mui/icons-material/Login';

import { useDispatch } from 'react-redux';
import { setToken } from '../../../toolkitRedux/toolkitReducer';
import { setUserDetails } from '../../../toolkitRedux/toolkitReducer';

const EasyRegInputCode = (props) => {
	const codeRef = useRef();
	const dispatch = useDispatch();
	const [messageBox, setMessageBox] = useState({ severity: 'info', text: '' });
	const [codeWaitRemain, setCodeWaitRemain] = useState('კოდის მოთხოვნა');
	const [invalidConfirmCode, setInvalidConfirmCode] = useState(true);
	const [waitCode, setWaitCode] = useState(false);
	const [uuCode, setUUCode] = useState();
	const [confirmCode, setConfirmCode] = useState();
	const [codeMethod, setCodeMethod] = useState();
	const [hintContext, setHintContext] = useState({
		severity: 'warning',
		text: 'აირჩიეთ შემოწმების მეთოდი',
	});
	const [cookies, setCookie] = useCookies('token');
	const navigate = useNavigate();

	const [fetching, isDataLoading, error] = useFetching(async (param) => {
		const response = await ApiService.userRegister(param);
		return response;
	});

	async function requestCode() {
		setOpen(true);
		const pars = {
			function: 'send_verification_code',
			pars: {
				method: props.methodValue.method,
				to_number: props.methodValue.value,
				to_email: props.methodValue.value,
			},
		};
		const ApiGet = await fetching(pars);
		const ApiData = ApiGet.data;
		if (ApiData.success === 'success') {
			const uucode = ApiData.uucode;
			setUUCode(uucode);
			setHintContext({
				severity: 'warning',
				text: 'კოდი გამოგზავნილია, შეამოწმეთ',
			});
			return uucode;
		} else {
			setHintContext({
				severity: 'warning',
				text: 'შეფერხება, სცადეთ თავიდან',
			});
		}
	}

	const handleRequestCode = () => {
		setCodeWaitRemain(60);
		setWaitCode(true);
		requestCode();
	};

	useEffect(() => {
		if (codeWaitRemain < 1) {
			setWaitCode(false);
			setCodeWaitRemain('0');
		}
	}, [codeWaitRemain]);

	const handleVerifyCodeChange = (e) => {
		const val = e.target.value.trim();
		setConfirmCode(val);
		if (val.length === 4) {
			setInvalidConfirmCode(false);
		} else {
			setInvalidConfirmCode(true);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && !invalidConfirmCode) {
			handleSubmit();
		}
	};

	useEffect(() => {
		let timer = null;
		if (waitCode) {
			timer = setInterval(() => {
				setCodeWaitRemain((seconds) => seconds - 1);
			}, 1000);
		}
		return () => {
			clearInterval(timer);
		};
	});

	useEffect(() => {
		handleRequestCode();
	}, []);

	const handleSubmit = async () => {
		setOpen(true);
		let pars = '';
		if (props.methodValue.method === 'sms') {
			pars = { phone: props.methodValue.value };
		} else {
			pars = { email: props.methodValue.value };
		}
		pars = { ...pars, uuCode: uuCode, confirmCode: confirmCode };
		const request = { function: 'user_verify_then_add_simple', pars: pars };
		const resp = await fetching(request);
		if (resp.data.success === 'success') {
			setMessageBox({ severity: 'info', text: '' });
			dispatch(setUserDetails(resp.data.data));
			// localStorage.setItem('userDetails', JSON.stringify(resp.data.data));
			localStorage.setItem('login_method', resp.data.login_method);
			const newToken = resp.data.token;
			const expires = new Date();
			expires.setDate(expires.getDate() + 30);
			setCookie('token', newToken, { expires: expires });
			dispatch(setToken(newToken));
			props.handleClose();
			navigate('/profile');
		} else {
			setMessageBox({ severity: 'error', text: resp.data.reason });
		}
	};

	const [open, setOpen] = React.useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const action = (
		<React.Fragment>
			<Button color="secondary" size="small" onClick={handleClose}>
				{' '}
			</Button>
			<IconButton size="small" aria-label="close" onClick={handleClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	const progress = codeWaitRemain * 1.6;

	return (
		<Box className={'box'}>
			<p align="center" className={'title'}>
				სისტემაში შესვლა
			</p>
			<div className={'auth-card'}>
				<Box sx={{ display: 'flex', alignItems: 'center', pt: 2.3 }}>
					<Box sx={{ width: '100%', mr: 1 }}>
						<LinearProgress variant="determinate" value={progress} />
					</Box>
					<Box sx={{ minWidth: { codeWaitRemain } }}>
						<Typography variant="body2" color="text.secondary">
							{(() => {
								if (codeWaitRemain > 0) {
									return <div className={'time'}>{codeWaitRemain}</div>;
								} else {
									return (
										<IconButton onClick={handleRequestCode} disabled={waitCode}>
											<ReplayIcon />
										</IconButton>
									);
								}
							})()}
						</Typography>
					</Box>
				</Box>
				<NumberFormat
					fullWidth
					sx={{ mt: 2 }}
					inputRef={codeRef}
					id="confirmCode"
					label="ერთჯერადი კოდი"
					format="####"
					size="small"
					customInput={TextField}
					onChange={handleVerifyCodeChange}
					onKeyPress={handleKeyPress}
					style={{
						fontSize: '16px',
						fontFamily: 'FiraGO',
					}}
					autoFocus
				/>
				<Button
					variant="contained"
					className={'button'}
					endIcon={<LoginIcon />}
					fullWidth
					sx={{ mt: 3 }}
					onClick={handleSubmit}
				>
					შესვლა / რეგისტრაცია
				</Button>
				{messageBox.text === '' && (
					<Snackbar
						open={open}
						autoHideDuration={6000}
						onClose={handleClose}
						action={action}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<Alert severity="success">
							კოდი გამოგზავნილია
							{props.methodValue.method === 'sms'
								? ' ნომერზე: '
								: ' ელ-ფოსტზე: '}
							{props.methodValue.value}
						</Alert>
					</Snackbar>
				)}

				{messageBox.text !== '' && (
					<Snackbar
						open={open}
						autoHideDuration={6000}
						onClose={handleClose}
						action={action}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						{/* <Alert   severity="danger" sx={{ mb:3 }} severity={messageBox.severity}>{messageBox.text}</Alert> */}
						<Alert severity="danger" sx={{ mb: 3 }}>
							თქვენს მიერ შეყვანილი კოდი არასწორია
						</Alert>
					</Snackbar>
				)}
			</div>
		</Box>
	);
};

export default EasyRegInputCode;
