import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import { useFetching } from '../../../hooks/useFetching';
import ApiService from '../../../API/ApiService';

import { DataGrid } from '@mui/x-data-grid';
import { Box, TextField, Button } from '@mui/material';

import { Grid, _ } from 'gridjs-react';

import { IconFileSpreadsheet } from '@tabler/icons';
import { IconFileText } from '@tabler/icons';



const ProfileSessionHistory = () => {

	const [rows, setRows] = useState([]);

	const token = useSelector((state) => state.toolkit.token);

	const [fetching, isLoading, error] = useFetching(async (param) => {
		const response = await ApiService.userApi(param);
		return response;
	});

	const columns = [
		{
			name: 'თარიღი',
			id: 'time_login',
			valueFormatter: params => moment(params?.value).format("DD/MM/YYYY HH:mm"),
			width: 130,
		},
		{
			name: 'IP მისამართი',
			id: 'user_ip',
			width: 120,
		},
		{
			name: 'ავტორიზაცია',
			id: 'authMethod',
			formatter: (id) => _((id === 'sms') ? 'მობილური ნომრით' : (id === 'email') ? 'ელექტრონული ფოსტით' : ''),
			width: 170,
		},
		{
			name: 'სისტემა',
			id: 'os',
			width: 100,
		},
		{
			name: 'ბრაუზერი',
			id: 'browser',
			width: 150,
		},
	]

	const loadSessionsHistory = async () => {
		const request = {
			function: 'getSessionsHistory',
			token: token
		};
		const response = await fetching(request);
		if (response.data.success = 'success') {
			setRows(response.data.data);
			// console.log('rows:', response.data.data);
		};
	};

	useEffect(() => {
		loadSessionsHistory();
	}, [])



	return (
		<div>
			<div className='profile-ip'>
				<div className={'title'}>
					<h1 className={'font, fs-14'}>IP მისამართების ისტორია</h1>
				</div>

				<TextField
					id="date"
					label="დაწყება"
					type="date"
					size="small"
					defaultValue="2017-05-24"
					sx={{ width: 120, mb: 2, mr: 2, mt: 2 }}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<TextField
					id="date"
					label="დასრულება"
					type="date"
					size="small"
					defaultValue="2017-05-24"
					sx={{ width: 120, mt: 2 }}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<Button sx={{ ml: 2, mt: 2, mr: 32 }} className='font' variant="contained">ძებნა</Button>


				<Button variant="text" sx={{ mt: 1.6 }} color="success" endIcon={<IconFileSpreadsheet />}>EXCEL</Button>
				<Button variant="text" sx={{ mt: 1.6 }} color="error" endIcon={<IconFileText />}>PDF</Button>

				<Grid
					data={rows}
					columns={columns}

					search={false}
					pagination={{
						enabled: true,
						limit: 10,
					}}
					language={{
						search: {
							placeholder: 'ძებნა'
						},
						pagination: {
							previous: 'უკან',
							next: 'წინ',
							showing: 'ნაჩვენებია',
							to: 'დან',
							of: 'სულ',
							results: () => 'ჩანაწერი'
						},
					}}

				/>
			</div>
		</div>

	);
};

export default ProfileSessionHistory;
