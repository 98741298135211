import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { IconEye } from '@tabler/icons';

import { IconFileSpreadsheet } from '@tabler/icons';
import { IconFileText } from '@tabler/icons';

import { Grid, _ } from 'gridjs-react';

import TextField from '@mui/material/TextField';

const PayHistory = () => {

	const row = [
		{ tel: '546546322', date: '10/09/2022', status: 'წარმატებული', card: '************ 3466', price: '50', currency: '₾', url: 'ნახვა', },
		{ tel: '546546322', date: '10/09/2022', status: 'წარმატებული', card: '************ 3466', price: '50', currency: '₾', url: 'ნახვა', },
		{ tel: '546546322', date: '10/09/2022', status: 'წარმატებული', card: '************ 3466', price: '50', currency: '₾', url: 'ნახვა', },
		{ tel: '546546322', date: '10/09/2022', status: 'წარმატებული', card: '************ 3466', price: '50', currency: '₾', url: 'ნახვა', },
	];

	const columns = [
		{
			name: 'ID',
			id: 'tel',
			width: 20,
		},
		{
			name: 'თარიღი',
			id: 'date',
			width: 20,
		},
		{
			name: 'ტრანზაქცია',
			id: 'status',
			width: 30,
		},
		{
			name: 'ბარათი',
			id: 'card',
			width: 50,
		},
		{
			name: 'თანხა',
			id: 'price',
			width: 10,
			// render: 
		},
		{
			name: '',
			id: 'url',
			width: 20,
		},
	]


	return (
		<div>
			<div className='pay-history'>
				<TextField
					id="date"
					label="დაწყება"
					type="date"
					size="small"
					defaultValue="2017-05-24"
					sx={{ width: 120, mb: 2, mr: 2 }}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<TextField
					id="date"
					label="დასრულება"
					type="date"
					size="small"
					defaultValue="2017-05-24"
					sx={{ width: 120 }}
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<Button sx={{ ml: 2 }} className='font' variant="contained">ძებნა</Button>
				<Grid
					data={row}
					columns={columns}
					search={false}
					pagination={{
						enabled: true,
						limit: 10,
						width: 100,
					}}
					language={{
						search: {
							placeholder: 'ძებნა'
						},
						pagination: {
							previous: 'უკან',
							next: 'წინ',
							showing: 'ნაჩვენებია',
							to: 'დან',
							of: 'სულ',
							results: () => 'ჩანაწერი'
						},
					}}
				/>
			</div>
			<div align='right'>
				<Button variant="text" sx={{ mt: 1.6 }} color="success" endIcon={<IconFileSpreadsheet />}>EXCEL</Button>
				<Button variant="text" sx={{ mt: 1.6 }} color="error" endIcon={<IconFileText />}>PDF</Button>
			</div>
		</div>
	);
};

export default PayHistory;