import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

import {
	Button,
	ButtonGroup,
	Box,
	CssBaseline,
	Grid,
	AppBar,
	Toolbar,
} from '@mui/material';

import DarkModeButton from '../Buttons/DarkModeButton';
import { setEasyRegistration } from '../../../toolkitRedux/toolkitReducer';
import EasyRegistration from '../Registration/EasyRegistration';

import logo2 from '../../../assets/images/iplog.png';

import IconButton from '@mui/material/IconButton';
import { IconUser } from '@tabler/icons';

import './UnauthorizedHeader.css';

const UnauthorizedHeader = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleSignUp = () => {
		// navigate("/signup");
		dispatch(setEasyRegistration(true));
	};

	const handleSingIn = () => {
		navigate('/signin');
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<AppBar color="inherit" elevation={0}>
			<Container>
				<Toolbar>
					<Grid container spacing={2} columns={6}>
						<Grid
							item
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							<Link href="/" className={'logo'}>
								<img
									src={logo2}
									height="50"
								/>
								{/* <span className='l1'>INTER</span><span className='l2'>PAY</span> */}
							</Link>
						</Grid>
						<Grid item xs></Grid>
						<Grid
							item
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
							className='auth-right'
						>
							<DarkModeButton />
							<IconButton
								onClick={handleSignUp}
								className={'avatar-picture'}
								sx={{
									mr: 1,
									"&.MuiButtonBase-root:hover": {
										bgcolor: "transparent"
									}
								}}
							>
								<IconUser />
							</IconButton>
							<a href='#' className='avatar-text' onClick={handleSignUp} >სისტემაში შესვლა</a>
						</Grid>
					</Grid>
				</Toolbar>
			</Container>
			<EasyRegistration />
		</AppBar>
	);
};

export default UnauthorizedHeader;
