import React, { useState, useEffect } from 'react';

import ProfilePhoneChange from './ProfilePhoneChange';
import ProfileEmailChange from './ProfileEmailChange';
import ProfileAddressChange from './ProfileAddressChange';
import ProfileValueChangeConfirm from './ProfileValueChangeConfirm';
import ProfileValueChangeSuccess from './ProfileValueChangeSuccess';
import ProfileValueChangeDenied from './ProfileValueChangeDenied';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const ProfileValueChange = (props) => {
	const action = props.action;
	const [page, setPage] = useState(1);
	const [newValues, setNewValues] = useState();
	const [messageText, setMessageText] = useState('');

	const propertyPage = {
		phone: {
			title: 'ტელეფონის ცვლილება',
			page: (
				<ProfilePhoneChange
					setNewValues={setNewValues}
					setPage={setPage}
					setMessageText={setMessageText}
				/>
			),
		},
		email: {
			title: 'ელფოსტის ცვლილება',
			page: (
				<ProfileEmailChange
					setNewValues={setNewValues}
					setPage={setPage}
					setMessageText={setMessageText}
				/>
			),
		},
		address: {
			title: 'მისამართის ცვლილება',
			page: (
				<ProfileAddressChange setNewValues={setNewValues} setPage={setPage} />
			),
		},
		addphone: {
			title: 'ტელეფონის დამატება',
			page: (
				<ProfilePhoneChange
					setNewValues={setNewValues}
					setPage={setPage}
					setMessageText={setMessageText}
				/>
			),
		},
		addemail: {
			title: 'ელფოსტის დამატება',
			page: (
				<ProfileEmailChange
					setNewValues={setNewValues}
					setPage={setPage}
					setMessageText={setMessageText}
				/>
			),
		},
	};

	const activePage = {
		1: propertyPage[action].page,
		2: (
			<ProfileValueChangeConfirm
				setPage={setPage}
				newValues={newValues}
				messageText={messageText}
			/>
		),
		3: <ProfileValueChangeSuccess />,
	};

	useEffect(() => {
		setPage(1);
	}, [action]);

	const steps = [propertyPage[action].title, 'ვერიფიკაცია', 'შედეგი'];

	return (
		<div className="profile-phone-mail" align="center">
			<Stepper activeStep={page - 1} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<div>{activePage[page]}</div>
		</div>
	);
};

export default ProfileValueChange;
