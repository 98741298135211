import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, IconButton, CssBaseline, Toolbar, AppBar } from '@mui/material';

import DefaultMenu from '../MenuList/DefaultMenu';
import AuthorizedHeader from '../Header/AuthorizedHeader';
import UnauthorizedHeader from '../Header/UnauthorizedHeader';

const Header = () => {
	const token = useSelector((state) => state.toolkit.token);
	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			{token ? <AuthorizedHeader /> : <UnauthorizedHeader />}
			<Box
				component="main"
				sx={{ p: 0, flexGrow: 1 }}
			>
				<Toolbar />
				<DefaultMenu />
			</Box>
		</Box>
	);
};

export default Header;