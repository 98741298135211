import React from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { IconSearch } from '@tabler/icons';

const PayTelecom = () => {
	return (
		<Grid>
			<Grid item xs={12}>
				<div className={'pay-box'}>
					<div className={'search'} align="center">
						<div className={'title'}>აბონენტის ძებნა</div>

						<div>
							<TextField
								fullWidth
								className={'inputRounded'}
								size="small"
								label="პირადი ნომრით ან საიდენტიფიკაციო კოდით"
								variant="outlined"
							/>
						</div>

						<div>
							<Button
								variant="contained"
								className={'button'}
								sx={{ mt: 3 }}
								endIcon={<IconSearch width="14" />}
								fullWidth
							>
								ძებნა
							</Button>
						</div>
					</div>
				</div>
			</Grid>

		</Grid>
	);
};

export default PayTelecom;