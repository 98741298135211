import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import validator from '../../../validator';

import { Snackbar, Alert } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import './Profile.css';
import UserLoad from '../../../API/UserLoad';

const ProfilePhoneChange = (props) => {
	const userDetails = useSelector((state) => state.toolkit.userDetails);
	const [newValues, setNewValues] = useState('');
	const [isValid, setIsValid] = useState(false);

	const token = useSelector((state) => state.toolkit.token);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertText, setAlertText] = useState('');

	const handleAlertClose = () => {
		setAlertOpen(false);
	};

	const alertAction = (
		<React.Fragment>
			<Button color="secondary" size="small" onClick={handleAlertClose}>
				{' '}
			</Button>
			<IconButton size="small" aria-label="close" onClick={handleAlertClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	const handleSubmit = async () => {
		const pars = { name: 'phone', value: newValues.phone };
		const response = await UserLoad.verifyOnDuplicates(token, pars);
		console.log(response);
		if (response.success == 'success') {
			if (!response.exists) {
				props.setMessageText(`კოდი გამოგზავნილია ნომერზე: ${newValues.phone}`);
				props.setNewValues(newValues);
				props.setPage(2);
			} else {
				setAlertText('მითითებული ნომერი უკვე რეგისტრირებულია სისტემაში.');
				setAlertOpen(true);
			}
		}
	};

	const changeValue = (e) => {
		if (validator.isMobilePhone(e.target.value, 'ka-GE')) {
			setIsValid(true);
			setNewValues({ phone: e.target.value });
		} else {
			setIsValid(false);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && isValid) {
			handleSubmit();
		}
	};

	return (
		<div className={'phone-mail-change-box'}>
			{userDetails.phone && (
				<div className={'phone-mail-change-box-title'}>
					თქვენი ნომერია:{' '}
					<span className="font-bold-p">{userDetails.phone}</span>
				</div>
			)}
			<div>
				<TextField
					fullWidth
					className={'inputRounded'}
					size="small"
					label="ახალი ნომერი"
					variant="outlined"
					autoFocus
					onChange={changeValue}
					onKeyPress={handleKeyPress}
				/>
			</div>
			<div>
				<Button
					variant="contained"
					className={'button'}
					sx={{ mt: 3 }}
					endIcon={<NavigateNextIcon />}
					fullWidth
					onClick={handleSubmit}
					disabled={!isValid}
				>
					გაგრძელება
				</Button>
			</div>
			<Snackbar
				open={alertOpen}
				autoHideDuration={6000}
				onClose={handleAlertClose}
				action={alertAction}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert severity="error">{alertText}</Alert>
			</Snackbar>
		</div>
	);
};

export default ProfilePhoneChange;
