import React from 'react';

const NoServices = () => {
	return (
		<div>
			<h1>დაგვიკავშირდით სერვისის გასააქტიურებლად</h1>
		</div>
	);
};

export default NoServices;
