const bottomMenu = [
	{
		id: "about",
		activeClass: "link-active",
		inactiveClass: "link",
		itemText: "ჩვენ შესახებ",
	},
	{
		id: "rules",
		activeClass: "link-active",
		inactiveClass: "link",
		itemText: "მომსახურების პირობები",
	},
	{
		id: "contacts",
		activeClass: "link-active",
		inactiveClass: "link",
		itemText: "კონტაქტი",
	},
	{
		id: "faq",
		activeClass: "link-active",
		inactiveClass: "link",
		itemText: "ხშირად დასმული კითხვები",
	},
];

export default bottomMenu;
