import React from 'react';

import Header from '../Layouts/Header';
import BodyFrame from '../Layouts/BodyFrame';
import Footer from '../Layouts/Footer';

import Payments from './Payments';

const index = () => {
	return (
		<>
			<Header />
			<BodyFrame />
			<div className={'page'}>
				<Payments />
			</div>
			<Footer />
		</>
	);
};

export default index;
