import React from 'react';

import Container from '@mui/material/Container';
import error404 from "../../../assets/images/404.png";

const NoPage = () => {
	return (
		<div className='page'>
			<Container align='center'>
				<div className='error404'>
					<img src={error404} height="500" />
					<h1 className={'font fs-16'}>შეცდომა 404, გვერდი ვერ მოიძებნა!</h1>
				</div>
			</Container>
		</div>
	);
};


export default NoPage;