import React, { } from 'react';

import Grid from '@mui/material/Grid';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';

import { IconTrash } from '@tabler/icons';

import { IconUser } from '@tabler/icons';
import { IconMoodSmile } from '@tabler/icons';
import { IconMessagePlus } from '@tabler/icons';
import { IconSend } from '@tabler/icons';
import { IconDotsVertical } from '@tabler/icons';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import MenuList from '@mui/material/MenuList';
import Check from '@mui/icons-material/Check';

import Menu from '@mui/material/Menu';

const SmsCenter = () => {

	const [openService, setOpenService] = React.useState(false);
	const [openDocument, setOpenDocument] = React.useState(false);
	const [open, setOpen] = React.useState(false);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openPhone, setOpenPhone] = React.useState(false);
	const [placement, setPlacement] = React.useState();

	const handleClick = (newPlacement) => (event) => {
		setAnchorEl(event.currentTarget);
		setOpenPhone((prev) => placement !== newPlacement || !prev);
		setPlacement(newPlacement);
	};

	const [anchorElDrop, setAnchorElDrop] = React.useState(null);
	const openDrop = Boolean(anchorElDrop);
	const handleClickDrop = (event) => {
		setAnchorElDrop(event.currentTarget);
	};
	const handleCloseDrop = () => {
		setAnchorElDrop(null);
	};
	return (
		<Grid container spacing={1} >
			<Grid item xs={3.5}>
				<div className='sms-center-contact'>
					<div className='sms-center-contact-search'>
						<Button className={'button'} fullWidth startIcon={<IconMessagePlus width='18' />}>
							ახალი შეტყობინება
						</Button>
					</div>
					<List>
						<ListItem className='sms-center-number'>
							<ListItemAvatar>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="+995596920202" secondary="ბოლო შეტყობინ" />
							<IconDotsVertical 
								className='sms-center-number-icon' 
								width='18px'
								id="basic-button"
								aria-controls={openDrop ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openDrop ? 'true' : undefined}
								onClick={handleClickDrop}
							/>
						</ListItem>
						<ListItem className='sms-center-number'>
							<ListItemAvatar>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="+995596920202" secondary="ბოლო შეტყობინ" />
							<IconDotsVertical 
								className='sms-center-number-icon' 
								width='18px'
								id="basic-button"
								aria-controls={openDrop ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openDrop ? 'true' : undefined}
								onClick={handleClickDrop}
							/>
						</ListItem>
						<ListItem className='sms-center-number'>
							<ListItemAvatar>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="+995596920202" secondary="ბოლო შეტყობინ" />
							<IconDotsVertical 
								className='sms-center-number-icon' 
								width='18px'
								id="basic-button"
								aria-controls={openDrop ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openDrop ? 'true' : undefined}
								onClick={handleClickDrop}
							/>
						</ListItem>
						<ListItem className='sms-center-number'>
							<ListItemAvatar>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="+995596920202" secondary="ბოლო შეტყობინ" />
							<IconDotsVertical 
								className='sms-center-number-icon' 
								width='18px'
								id="basic-button"
								aria-controls={openDrop ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openDrop ? 'true' : undefined}
								onClick={handleClickDrop}
							/>
						</ListItem>
						<ListItem className='sms-center-number'>
							<ListItemAvatar>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="+995596920202" secondary="ბოლო შეტყობინ" />
							<IconDotsVertical 
								className='sms-center-number-icon' 
								width='18px'
								id="basic-button"
								aria-controls={openDrop ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openDrop ? 'true' : undefined}
								onClick={handleClickDrop}
							/>
						</ListItem>
						<ListItem className='sms-center-number'>
							<ListItemAvatar>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="+995596920202" secondary="ბოლო შეტყობინ" />
							<IconDotsVertical 
								className='sms-center-number-icon' 
								width='18px'
								id="basic-button"
								aria-controls={openDrop ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openDrop ? 'true' : undefined}
								onClick={handleClickDrop}
							/>
						</ListItem>
						<ListItem className='sms-center-number'>
							<ListItemAvatar>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="+995596920202" secondary="ბოლო შეტყობინ" />
							<IconDotsVertical 
								className='sms-center-number-icon' 
								width='18px'
								id="basic-button"
								aria-controls={openDrop ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openDrop ? 'true' : undefined}
								onClick={handleClickDrop}
							/>
						</ListItem>
						<ListItem className='sms-center-number'>
							<ListItemAvatar>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="+995596920202" secondary="ბოლო შეტყობინ" />
							<IconDotsVertical 
								className='sms-center-number-icon' 
								width='18px'
								id="basic-button"
								aria-controls={openDrop ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openDrop ? 'true' : undefined}
								onClick={handleClickDrop}
							/>
						</ListItem>
						<ListItem className='sms-center-number'>
							<ListItemAvatar>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="+995596920202" secondary="ბოლო შეტყობინ" />
							<IconDotsVertical 
								className='sms-center-number-icon' 
								width='18px'
								id="basic-button"
								aria-controls={openDrop ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openDrop ? 'true' : undefined}
								onClick={handleClickDrop}
							/>
						</ListItem>
						<ListItem className='sms-center-number'>
							<ListItemAvatar>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="+995596920202" secondary="ბოლო შეტყობინ" />
							<IconDotsVertical 
								className='sms-center-number-icon' 
								width='18px'
								id="basic-button"
								aria-controls={openDrop ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openDrop ? 'true' : undefined}
								onClick={handleClickDrop}
							/>
						</ListItem>
						<ListItem className='sms-center-number'>
							<ListItemAvatar>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="+995596920202" secondary="ბოლო შეტყობინ" />
							<IconDotsVertical 
								className='sms-center-number-icon' 
								width='18px'
								id="basic-button"
								aria-controls={openDrop ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={openDrop ? 'true' : undefined}
								onClick={handleClickDrop}
							/>
						</ListItem>
					</List>
				</div>

				<Menu
					id="basic-menu"
					anchorEl={anchorElDrop}
					open={openDrop}
					onClose={handleCloseDrop}
					MenuListProps={{
					'aria-labelledby': 'basic-button',
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<MenuItem onClick={handleCloseDrop}>კონტაქტებში დამატება</MenuItem>
					<MenuItem onClick={handleCloseDrop}>წაშლა</MenuItem>
				</Menu>
			</Grid>
			<Grid item xs={8.5}>
				<div className='sms-center-message'>
					<ListItem className='sms-center-message-name'>
						<ListItemAvatar>
							<Avatar className='bg-primary'>
								<IconUser />
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary="+995596920202" />
						<IconDotsVertical className='text-secondary' width='18px' />
					</ListItem>

					<div className='sms-center-message-text'>
						<div align='left' className='sms-center-message-text-other'>
							<ListItemAvatar className='sms-center-message-text-other-icon'>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>

							<div className='left-sms'>
								<div className='sms-center-message-text-other-text'>ჩემი მიწერილი შეტყობინება შეტყობინება</div>
								<IconDotsVertical className='button' width='18px' />
							</div>
							<div className='date'>09/09/2022 09:00</div>
						</div>
						<div align='left' className='sms-center-message-text-other'>
							<ListItemAvatar className='sms-center-message-text-other-icon'>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>

							<div className='left-sms'>
								<div className='sms-center-message-text-other-text'>ჩემი მიწერილი შეტყობინება შეტყობინება</div>
								<IconDotsVertical className='button' width='18px' />
							</div>
							<div className='date'>09/09/2022 09:00</div>
						</div>
						<div align='left' className='sms-center-message-text-other'>
							<ListItemAvatar className='sms-center-message-text-other-icon'>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>

							<div className='left-sms'>
								<div className='sms-center-message-text-other-text'>ჩემი მიწერილი შეტყობინება შეტყობინება</div>
								<IconDotsVertical className='button' width='18px' />
							</div>
							<div className='date'>09/09/2022 09:00</div>
						</div>
						<div align='left' className='sms-center-message-text-other'>
							<ListItemAvatar className='sms-center-message-text-other-icon'>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>

							<div className='left-sms'>
								<div className='sms-center-message-text-other-text'>ჩემი მიწერილი შეტყობინება შეტყობინება</div>
								<IconDotsVertical className='button' width='18px' />
							</div>
							<div className='date'>09/09/2022 09:00</div>
						</div>
						<div align='left' className='sms-center-message-text-other'>
							<ListItemAvatar className='sms-center-message-text-other-icon'>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>

							<div className='left-sms'>
								<div className='sms-center-message-text-other-text'>ჩემი მიწერილი შეტყობინება შეტყობინება</div>
								<IconDotsVertical className='button' width='18px' />
							</div>
							<div className='date'>09/09/2022 09:00</div>
						</div>

						<div align='right' className='sms-center-message-text-me'>
							<div className='sms-center-message-text-me-text'>მოწერილი შეტყობინება მოწერილი შეტყობინება მოწერილი შეტყობინება</div>
							<div className='date'>09/09/2022 09:00</div>
						</div>
						<div align='left' className='sms-center-message-text-other'>
							<ListItemAvatar className='sms-center-message-text-other-icon'>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>

							<div className='left-sms'>
								<div className='sms-center-message-text-other-text'>ჩემი მიწერილი შეტყობინება შეტყობინება</div>
								<IconDotsVertical className='button' width='18px' />
							</div>
							<div className='date'>09/09/2022 09:00</div>
						</div>
						<div align='left' className='sms-center-message-text-other'>
							<ListItemAvatar className='sms-center-message-text-other-icon'>
								<Avatar className='bg-primary'>
									<IconUser />
								</Avatar>
							</ListItemAvatar>

							<div className='left-sms'>
								<div className='sms-center-message-text-other-text'>ჩემი მიწერილი შეტყობინება შეტყობინება</div>
								<IconDotsVertical className='button' width='18px' />
							</div>
							<div className='date'>09/09/2022 09:00</div>
						</div>

						<div align='right' className='sms-center-message-text-me'>
							<div className='sms-center-message-text-me-text'>მოწერილი შეტყობინება</div>
						</div>
					</div>
					<div className='sms-center-message-new'>
						<Grid container spacing={1} >
							<Grid item xs={2.8}>
								<Button onClick={handleClick('top-start')} aria-label="sent" className='text-secendary'>
									+995596920202 <KeyboardArrowDownIcon />
								</Button>

								<Popper open={openPhone} anchorEl={anchorEl} placement={placement} transition >
									{({ TransitionProps }) => (
										<Fade {...TransitionProps} timeout={350}>
											<Paper>
												<MenuList dense>
													<MenuItem>
														<ListItemText inset>+995598776655</ListItemText>
													</MenuItem>
													<MenuItem>
														<ListItemIcon>
															<Check />
														</ListItemIcon>
														+995596920202
													</MenuItem>
												</MenuList>
											</Paper>
										</Fade>
									)}
								</Popper>
							</Grid>
							<Grid item xs={7.5}>
								<TextField id="outlined-basic" multiline maxRows={5} size="small" className="inputRounded" fullWidth label="შეტყობინება" variant="outlined" />
							</Grid>
							<Grid item xs={0.7}>
								<IconButton aria-label="sent" className='text-secendary'>
									<IconMoodSmile />
								</IconButton>
							</Grid>
							<Grid item xs={0.7}>
								<IconButton aria-label="sent" color="primary">
									<IconSend />
								</IconButton>
							</Grid>
						</Grid>
					</div>
				</div>
			</Grid>
		</Grid>
	);
};

export default SmsCenter;
