import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormControl, InputLabel, Select } from '@mui/material';

import Container from '@mui/material/Container';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { IconHome2 } from '@tabler/icons';

import './MyService.css';

import { menuItems, pages } from './menu-items';

import Navlink from '../Breadcrumbs';
import ServiceProviders from './ServiceProviders';
import MenuGroup from '../MenuList/MenuGroup';
import NoServices from './NoServices';

const MyServices = () => {
	const params = useParams();
	const pageID = params.id ? params.id : 'current';

	const links = [
		{ title: 'ჩემი სერვსები', link: '' },
		{ title: pages[pageID]?.title },
	];

	return (
		<div>
			<Navlink links={links} />
			<Container>
				<Grid container spacing={2} align="left" className={'service'}>
					<Grid item xs={3.5}>
						<div className="change">
							<ServiceProviders />
						</div>
						<div className={'menu-list'}>
							<div className={'box'}>
								<MenuGroup item={menuItems} current={pageID} pages={pages} />
							</div>
						</div>
					</Grid>
					<Grid item xs={8.5}>
						{/* {pageContent} */}
						{/* {pages[pageID].target} */}
						<NoServices />
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default MyServices;
