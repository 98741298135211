import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	Dialog,
	DialogContent,
	Container,
	Button,
	DialogTitle,
	Grid,
} from '@mui/material';

import ApiService from '../../../API/ApiService';
import UserLoad from '../../../API/UserLoad';
import { useFetching } from '../../../hooks/useFetching';
import { setUserDetails } from '../../../toolkitRedux/toolkitReducer';

import { IconX } from '@tabler/icons';

import { useDispatch, useSelector } from 'react-redux';

import logo2 from '../../../assets/images/iplog.png';

// import { userLoad } from '../../../hooks/useFunctions';

const VerifyFrame = (props) => {
	const navigate = useNavigate();
	const token = useSelector((state) => state.toolkit.token);
	const dispatch = useDispatch();

	const [url, setUrl] = useState('');
	const verifyFrameOpen = props.verifyFrameOpen;
	const setVerifyFrameOpen = props.setVerifyFrameOpen;
	const [openDialog, setOpenDialog] = useState(false);
	const [identoSessionToken, setIdentoSessionToken] = useState(false);

	const [featching, isDataLoading, error] = useFetching(async (param) => {
		const response = await ApiService.userApi(param);
		return response;
	});

	const userReLoad = async () => {
		const response = await UserLoad.userLoad(token);
		if (response.success === "success") {
			dispatch(setUserDetails(response.data));
			return true;
		} else {
			return false;
		}
	}

	const requestSession = async () => {
		const request = {
			token: token,
			function: 'identoRequestSession',
			pars: {},
		};
		const response = await featching(request);
		const myData = response.data;
		if (myData.success === 'success') {
			setIdentoSessionToken(myData.session_token);
			setUrl(myData.url);
			// console.log('myData', myData);
			// console.log('session_token', myData.session_token);
		}
		return myData;
	};

	const getResults = async (myData) => {
		// console.log('myData R', myData);
		// console.log('Result:', identoSessionToken, token);
		if (myData.session_token && token) {
			// console.log('Result approach');
			const request = {
				token: token,
				function: 'identoFullProcessing',
				pars: {
					session_token: myData.session_token,
					save_data: true,
				},
			};
			const response = await featching(request);
			if (response.data.success === 'success') {
				// gotoPage of Profile
				// Needs to reread user profile from DB
				// console.log('userUpdate', response);
				// navigate('/profile');
				// window.location.reload(false);
			} else {
				// popup problem info
				props.setAlertText(response.data.reason);
				props.setAlertOpen(true);
			}
			await userReLoad();
		}
	};

	const handleOpenDialog = async () => {
		const myData = await requestSession();
		setOpenDialog(true);
		// identoFrame
		window.addEventListener('message', function (e) {
			if (e.origin !== 'https://widget.identomat.com') return;
			if (e.data !== 'DONE') return;
			// process done, check for result here
			// setOpenDialog(false);
			getResults(myData);
			handleCloseDialog();
		});
	};

	const handleCloseDialog = () => {
		setVerifyFrameOpen(false);
		setOpenDialog(false);
	};

	const handleGetResults = async () => {
		const response = await UserLoad.userLoad(token);
		// console.log('userUpdate', response);
	};

	useEffect(() => {
		if (token && verifyFrameOpen) {
			handleOpenDialog();
		}
	}, [verifyFrameOpen]);

	// useEffect(() => {
	// 	handleOpenDialog();
	// }, []);

	return (
		<div className={'page'}>
			<Container>
				{/* <Button onClick={handleOpenDialog}>OPEN</Button>
				<Button onClick={handleGetResults}>GET RES</Button> */}
				<Dialog
					open={openDialog}
					PaperProps={{
						style: {
							minHeight: '90%',
							maxHeight: '90%',
							minWidth: '90%',
						},
					}}
				>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<img src={logo2} height="30" />
							</Grid>
							<Grid item xs={4} align="center">
								<span className="font-bold">ვერიფიკაცია</span>
							</Grid>
							<Grid item xs={4} align="right">
								<Button onClick={handleCloseDialog}>
									<IconX />
								</Button>
							</Grid>
						</Grid>
						<iframe
							title="verify_ident"
							src={url}
							width="100%"
							height="750"
							frameBorder="0"
							allow="camera"
						/>
					</DialogContent>
				</Dialog>
			</Container>
		</div>
	);
};

export default VerifyFrame;
