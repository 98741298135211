import React, { useState } from 'react';
import PayProviderServicesDetails from './PayProviderServicesDetails';

import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { IconShoppingCartPlus } from '@tabler/icons';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { IconStar } from '@tabler/icons';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { IconCreditCard } from '@tabler/icons';

import { Dialog } from '@mui/material';

const PayProviderServices = (props) => {

	const paySummary = props.paySummary;
	const [openPayDetails, setOpenPayDetails] = useState(false);

	const handleClickDetails = () => {
		setOpenPayDetails(true);
	};

	const handleCloseDetails = () => {
		setOpenPayDetails(false);
	};
	return (
		<Grid item xs={6}>
			<div className={'pay-box'}>
				<div className="box">
					<div className="box-header">
						<Grid container spacing={2} align="left">
							<Grid item xs={6.8}>
								<div className="title">{paySummary.provider}</div>
								<div className="category">{paySummary.customer}</div>
							</Grid>
							<Grid item xs={5.2}>
								<Grid container spacing={0} align="right">
									<Grid item xs={3}>
										<Tooltip title="რჩეულებში დამატება" arrow>
											<IconButton href="#" className='header-icon' ><IconStar /></IconButton>
										</Tooltip>
									</Grid>
									<Grid item xs={9} >
										<div className="box-company-logo" align="right">
											<img src={paySummary.providerLogo} />
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</div>
					<div className="box-body">
						<FormControl fullWidth size="small">
							<InputLabel id="demo-simple-select-label">
								გადახდის მეთოდი
							</InputLabel>
							<Select
								labelId="demo-select-small"
								id="demo-select-small"
								label="გადახდის მეთოდი"
							>
								<MenuItem>ქართული ბარათით</MenuItem>
								<MenuItem>უცხოური ბარათით</MenuItem>
							</Select>
						</FormControl>
						<br></br>
						<br></br>
						<div className="text ">სერვისების რაოდენობა: {paySummary.servicesCount}</div>
						<div className="text ">გადასახდელი თანხა: {paySummary.summary} {paySummary.currency}</div>
						<div className="text ">საკომისიო: {paySummary.commision} {paySummary.currency}</div>
						<div className="text ">
							სულ გადასახდელი თანხა: {paySummary.total} {paySummary.currency}
						</div>
						<Button
							variant="text"
							className=" fs-14 text-primary"
							onClick={handleClickDetails}
							sx={{ p: 0 }}
							endIcon={<InfoIcon />}
						>
							დეტალები
						</Button>
					</div>
					<div className="box-footer">
						<Grid container spacing={2}>
							<Grid item xs={7.2}>
								<Button
									variant="text"
									sx={{ pl: 0, pr: 0 }}
									className="font"
									fullWidth
									startIcon={<IconShoppingCartPlus />}
								>
									გადახდებში დამატება
								</Button>
							</Grid>
							<Grid item xs={4.8}>
								<Button
									className={'button'}
									fullWidth
									endIcon={<IconCreditCard />}
								>
									გადახდა
								</Button>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
			<Dialog
				open={openPayDetails}
				onClose={handleCloseDetails}
				PaperProps={{ style: { borderRadius: 6 } }}
				maxWidth="lg"
			>
				<PayProviderServicesDetails paySummary={paySummary} />
			</Dialog>
		</Grid>
	);
};

export default PayProviderServices;