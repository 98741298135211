import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ApiService from '../../../API/ApiService';
import { useFetching } from '../../../hooks/useFetching';

import { TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ProfileAddressChange = (props) => {

	const userDetails = JSON.parse(localStorage.getItem('userDetails'));
	const [newValues, setNewValues] = useState({ city: userDetails.city, address: '' });
	const [cities, setCities] = useState([]);

	const handleSubmit = () => {
		props.setNewValues(newValues);
		props.setPage(2);
	}

	const token = useSelector((state) => state.toolkit.token);

	const [featching, isDataLoading, error] = useFetching(async (param) => {
		const response = await ApiService.userApi(param);
		return response;
	});

	const loadCities = async () => {
		const pars = {
			token: token,
			function: 'cities_list',
			pars: { status: '1' },
		};
		const ApiGet = await featching(pars);
		const ApiData = ApiGet.data;
		if (ApiData.success === 'success') {
			setCities(ApiGet.data.data);
		} else {
			// setHintContext({ severity: 'warning', text: 'შეფერხება, სცადეთ თავიდან' });
		}
	};

	useEffect(() => {
		loadCities();
	}, []);

	return (
		<div className={'content'} >
			<div className={'new-phone-mail'}>თქვენი მისამართია: <span className='font-bold-p'>{userDetails.address}</span></div>
			<div>
				<TextField
					select
					className={'inputRounded'}
					fullWidth
					size="small"
					label="ქალაქი/დაბა"
					value={
						cities.some((e) => e.id === newValues.city) ? newValues.city : ''
					}
					onChange={(event) => {
						setNewValues({ ...newValues, city: event.target.value });
					}}
				>
					{cities?.map((option) => (
						<MenuItem key={option.id} value={option.id}>
							{option.city_name}
						</MenuItem>
					))}
				</TextField>
			</div>
			<div>
				<TextField
					fullWidth
					className={'inputRounded'}
					size="small"
					sx={{ mt: 2 }}
					label="ახალი მისამართი"
					variant="outlined"
					autoFocus
					onChange={(event) => {
						setNewValues({ ...newValues, address: event.target.value });
					}}
				/>
			</div>
			<div>
				<Button
					variant="contained"
					className={'button'}
					sx={{ mt: 3 }}
					endIcon={<NavigateNextIcon />}
					fullWidth
					onClick={handleSubmit}
				>
					გაგრძელება
				</Button>
			</div>
		</div>
	);
};

export default ProfileAddressChange;
