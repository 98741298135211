import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import './assets/css/bpg-arial.css';
import './assets/css/bpg-arial-caps.css';
import './assets/css/bpg-rioni.css';
import './assets/css/bpg-rioni-vera.css';
import './assets/css/dejavu-sans.css';
import './assets/css/bog-2017-headline.css';
import './assets/css/bog-2018.css';
import './assets/css/style.css'; 

import App from './components/App/App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { store } from './toolkitRedux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<App />
	</Provider>
);

reportWebVitals();
