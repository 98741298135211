import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DynamicPages from './../ui/Pages/DynamicPages';

import NoPage from '../ui/Pages/NoPage';
import Cart from '../ui/Pages/Cart';
import Payments from '../ui/Payments';
import PaymentOrderShow from '../ui/PaymentOrder/PaymentOrderShow';

import MyServices from '../ui/MyServices';

import HomePage from '../ui/Pages/HomePage';
import Messages from '../ui/Messages';
import Profile from '../ui/Profile';
import AuthRequired from '../ui/Pages/AuthRequired';

const MyRoutes = () => {
	const token = useSelector((state) => state.toolkit.token);
	return (
		<div>
			<BrowserRouter>
				<Routes>

					{token ? (
						<>
							<Route path="payment-order" element={<PaymentOrderShow />} />
							<Route path="messages/:id" element={<Messages />} />
							<Route path="messages" element={<Messages />} />
							<Route path="myservices/:id" element={<MyServices />} />
							<Route path="myservices" element={<MyServices />} />
							<Route path="profile/:id" element={<Profile />} />
							<Route path="profile" element={<Profile />} />
						</>
					) : (
						<>
							<Route path="messages/:id" element={<AuthRequired />} />
							<Route path="messages" element={<AuthRequired />} />
							<Route path="myservices/:id" element={<AuthRequired />} />
							<Route path="myservices" element={<AuthRequired />} />
							<Route path="profile/:id" element={<AuthRequired />} />
							<Route path="profile" element={<AuthRequired />} />
						</>
					)}

					<Route path="/" element={<HomePage />} />
					<Route path="cart" element={<Cart />} />
					<Route path="pages/:page" element={<DynamicPages />} />
					<Route path="payments" element={<Payments />} />
					<Route path="payments/:id" element={<Payments />} />

					<Route path="*" element={<NoPage />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
};

export default MyRoutes;
