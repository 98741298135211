import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDarkMode } from '../../../toolkitRedux/toolkitReducer';

import { Checkbox, Tooltip } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { IconSun } from '@tabler/icons';

import { IconMoon } from '@tabler/icons';


const DarkModeButton = () => {

	const darkMode = useSelector(state => state.toolkit.darkMode);
	const dispatch = useDispatch();

	const handleThemeChange = () => {
		dispatch(setDarkMode(!darkMode));
	}

	return (
		<Tooltip title={darkMode ? 'ღია ფონი' : 'მუქი ფონი'} arrow>
			<Checkbox
				icon={<IconMoon />}
				checkedIcon={<IconSun />}
				checked={darkMode}
				onClick={handleThemeChange}
				sx={{
					'&.Mui-checked': {
						color: 'white'
					}
				}}
				className='header-icon'
			/>
		</Tooltip>
	);
};

export default DarkModeButton;