import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
	darkMode: false,
	styleName: "themeLight",
	muiTheme: "light",
	isLoading: false,
	token: false,
	userDetails: false,
	easyRegistration: false,
	menuSelected: undefined,
	unreadedMessages: 0,
	submenu: undefined,
};

export const setDarkMode = createAction("setMode");
export const setIsLoading = createAction("isLoading");
export const setToken = createAction("token");
export const setUserDetails = createAction("userDetails");
export const setEasyRegistration = createAction("easyRegistration");
export const setMenuSelected = createAction("menuSelected");
export const setUnreadedMessages = createAction("unreadedMessages");
export const setSubmenu = createAction("submenu");

export default createReducer(initialState, {
	[setDarkMode]: function (state, action) {
		state.darkMode = action.payload;
		state.styleName = state.darkMode ? "themeDark" : "themeLight";
		state.muiTheme = state.darkMode ? "dark" : "light";
	},
	[setIsLoading]: function (state, action) {
		state.isLoading = action.payload;
	},
	[setToken]: function (state, action) {
		// const [cookies, setCookie] = useCookies('darkMode');
		state.token = action.payload;
		// setCookie('token', darkMode);
	},
	[setUserDetails]: function (state, action) {
		state.userDetails = action.payload;
	},
	[setEasyRegistration]: function (state, action) {
		state.easyRegistration = action.payload;
	},
	[setMenuSelected]: function (state, action) {
		state.menuSelected = action.payload;
	},
	[setUnreadedMessages]: function (state, action) {
		state.unreadedMessages = action.payload;
	},
	[setSubmenu]: function (state, action) {
		state.submenu = action.payload;
	},
});
