import React, { useState, useRef, useEffect } from 'react';

import validator from '../../../validator';

import { useDispatch, useSelector } from 'react-redux';
import { setEasyRegistration } from './../../../toolkitRedux/toolkitReducer';

import { Box, Button, TextField } from '@mui/material';
import { RadioGroup } from '@mui/material';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import LoginIcon from '@mui/icons-material/Login';

import './registration.css';

const EasyRegInputPhone = (props) => {
	const dispatch = useDispatch();

	const labels = { sms: 'მობილურის ნომერი', email: 'ელ-ფოსტის მისამართი' };

	const [invalid, setInvalid] = useState(true);
	const [label, setLabel] = useState(labels.sms);
	const [method, setMethod] = useState('sms');

	const valueRef = useRef();

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && !invalid) {
			// setSubmit('');
			props.setStep(2);
		}
	};

	const handleSubmit = () => {
		props.setStep(2);
	};

	const handleLabel = (e) => {
		valueRef.current.value = '';
		const labelVal = e.target.value;
		setMethod(labelVal);
		setLabel(labels[labelVal]);
		// validate();
		setInvalid(true);
	};

	const validate = () => {
		const value = valueRef.current.value;
		props.setMethodValue({ method, value });
		switch (method) {
			case 'sms':
				if (validator.isMobilePhone(value, 'ka-GE')) {
					setInvalid(false);
				} else {
					setInvalid(true);
				}
				break;
			case 'email':
				if (validator.isEmail(value)) {
					setInvalid(false);
				} else {
					setInvalid(true);
				}
				break;
			default:
				setInvalid(true);
		}
	};

	useEffect(() => {
		console.log('invalid: ', invalid);
	}, [invalid]);

	return (
		<Box className={'box'}>
			<p align="center" className={'title'}>
				სისტემაში შესვლა
			</p>

			<div className={'auth-card'}>
				<RadioGroup
					row
					aria-labelledby="demo-row-radio-buttons-group-label"
					name="row-radio-buttons-group"
					onChange={handleLabel}
					value={method}
					sx={{ mt: 2 }}
				>
					<FormControlLabel value="sms" control={<Radio />} label="მობილური" />
					<FormControlLabel
						value="email"
						control={<Radio />}
						label="ელ-ფოსტა"
					/>
				</RadioGroup>
				<TextField
					fullWidth
					sx={{ mt: 2 }}
					inputRef={valueRef}
					id="pHeMvalue"
					label={label}
					size="small"
					textField
					className="inputRounded"
					// error={invalid}
					onKeyPress={handleKeyPress}
					onChange={validate}
					autoFocus
				/>
				<Button
					variant="contained"
					className={'button'}
					fullWidth
					sx={{ mt: 3, mb: 0 }}
					endIcon={<LoginIcon />}
					onClick={handleSubmit}
					disabled={invalid}
				>
					შესვლა / რეგისტრაცია
				</Button>
			</div>
		</Box>
	);
};

export default EasyRegInputPhone;
