import React from 'react';

import Header from '../Layouts/Header';
import BodyFrame from '../Layouts/BodyFrame';
import Footer from '../Layouts/Footer';

import { Container, Grid } from '@mui/material';

import './HomePage.css';

const AuthRequired = () => {
	return (
		<>
			<Header />
			<BodyFrame />
			<div className={'page'}>
				<Container>
					<Grid container spacing={4}>
						<Grid item xs={12} align='center'>
							<h1 className='font-bold fs-24 text-primary'>ამ გვერდის სანახავად საჭიროა სისტემაში შესვლა</h1>
						</Grid>
					</Grid>
				</Container>
			</div>
			<Footer />
		</>
	);
};

export default AuthRequired;