import {
	IconCategory,
	IconDeviceMobileMessage,
	IconClipboardText,
	IconReceipt,
	IconMapPin,
} from "@tabler/icons";

import SmsCenter from "./SmsCenter";
import MyContacts from "./MyContacts";
import CallHistory from "./CallHistory";
import ReciptsPayments from "./ReciptsPayments";
import DocInvoices from "./DocInvoices";
import DocAgreements from "./DocAgreements";
import DocContracts from "./DocContracts";
import MyAddresses from "./MyAddresses";
import CurrentServices from "./CurrentServices";
import MyOrders from "./MyOrders";

const icons = {
	IconCategory,
	IconDeviceMobileMessage,
	IconClipboardText,
	IconReceipt,
	IconMapPin,
};

const menuItems = [
	{
		id: "myServices",
		icon: icons.IconCategory,
		title: "სერვისები",
		type: "collapse",
		children: [
			{
				id: "current",
				title: "მიმდინარე",
				type: "item",
				url: "/cabinet/current",
				target: "/myservices/current",
				onClick: <CurrentServices />,
			},
			{
				id: "orders",
				title: "შეკვეთები",
				type: "item",
				url: "/cabinet/orders",
				target: "/myservices/orders",
				onClick: <MyOrders />,
			},
		],
	},
	{
		id: "myPhone",
		icon: icons.IconDeviceMobileMessage,
		title: "SMS ცენტრი",
		type: "collapse",
		children: [
			{
				id: "smsc",
				title: "შეეტყობინებები",
				type: "item",
				url: "/cabinet/smsCenter",
				target: "/myservices/smsc",
				onClick: <SmsCenter />,
			},
			{
				id: "contacts",
				title: "კონტაქტები",
				type: "item",
				url: "/cabinet/Contact",
				target: "/myservices/contacts",
				onClick: <MyContacts />,
			},
		],
	},
	{
		id: "myDocuments",
		icon: icons.IconClipboardText,
		title: "დოკუმენტები",
		type: "collapse",
		children: [
			{
				id: "invoices",
				title: "ინვოისი",
				type: "item",
				url: "/cabinet/current",
				target: "/myservices/invoices",
				onClick: <DocInvoices />,
			},
			{
				id: "agreements",
				title: "მიღება-ჩაბარება",
				type: "item",
				url: "/cabinet/orders",
				target: "/myservices/agreements",
				onClick: <DocAgreements />,
			},
			{
				id: "contracts",
				title: "ხელშეკრულება",
				type: "item",
				url: "/cabinet/orders",
				target: "/myservices/contracts",
				onClick: <DocContracts />,
			},
		],
	},
	{
		id: "myRecipts",
		icon: icons.IconReceipt,
		title: "ამონაწერი",
		type: "collapse",
		children: [
			{
				id: "payments",
				title: "გადახდები",
				type: "item",
				url: "/cabinet/current",
				target: "/myservices/payments",
				onClick: <ReciptsPayments />,
			},
			{
				id: "callshistory",
				title: "ტელეფონი",
				type: "item",
				url: "/cabinet/orders",
				target: "/myservices/callshistory",
				onClick: <CallHistory />,
			},
		],
	},
	{
		id: "addresses",
		icon: icons.IconMapPin,
		title: "მისამართი",
		type: "item",
		target: "/myservices/addresses",
		onClick: <MyAddresses />,
	},
];

const pages = {
	current: {
		parent: "myServices",
		title: "სერვისები - მიმდინარე",
		target: <CurrentServices />,
	},
	orders: {
		parent: "myServices",
		title: "სერვისები - შეკვეთები",
		target: <MyOrders />,
	},
	smsc: {
		parent: "myPhone",
		title: "SMS ცენტრი - შეეტყობინებები",
		target: <SmsCenter />,
	},
	contacts: {
		parent: "myPhone",
		title: "SMS ცენტრი - კონტაქტები",
		target: <MyContacts />,
	},
	invoices: {
		parent: "myDocuments",
		title: "დოკუმენტები - ინვოისი",
		target: <DocInvoices />,
	},
	agreements: {
		parent: "myDocuments",
		title: "დოკუმენტები - მიღება-ჩაბარება",
		target: <DocAgreements />,
	},
	contracts: {
		parent: "myDocuments",
		title: "დოკუმენტები - ხელშეკრულება",
		target: <DocContracts />,
	},
	payments: {
		parent: "myRecipts",
		title: "ამონაწერი - გადახდები",
		target: <ReciptsPayments />,
	},
	callshistory: {
		parent: "myRecipts",
		title: "ამონაწერი - ტელეფონი",
		target: <CallHistory />,
	},
	addresses: {
		title: "მისამართი",
		target: <MyAddresses />,
	},
};

export { menuItems, pages };
