import React, {} from 'react';

import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ProfileChangeVerify = (props) => {
	
	return (
		<div className='profile-phone-mail' align='center'>
            <div className='font' sx={{ mt:3 }}>შეცვლის კოდის გაგზავნა</div>
			<div>
				<FormControlLabel value="phone" control={<Radio/>} label="ტელეფონი" />
				<FormControlLabel value="email" control={<Radio/>} label="ელფოსტა" />
			</div>
            <div>
				<Button
					variant="contained"
					className={'button'}
					sx={{ mt: 3 }}
					endIcon={<NavigateNextIcon />}
				>
					გაგრძელება
				</Button>
			</div>
		</div>
	);
};

export default ProfileChangeVerify;
