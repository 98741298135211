import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import { IconShoppingCartPlus } from '@tabler/icons';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { IconStar } from '@tabler/icons';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { IconCreditCard } from '@tabler/icons';

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Typography } from '@mui/material';

const PayProviderServicesDetails = (props) => {

	const paySummary = props.paySummary;
	const [payDetails, setPayDetails] = useState(paySummary.details);

	return (
		<DialogContent>
			<DialogContentText>
				<div className={'pay-box-dialog'}>
					<div className="header">
						<Grid container spacing={2} align="left">
							<Grid item xs={8}>
								<div className="title">{paySummary.provider}</div>
								<div className="category">აბონენტი: {paySummary.customer}</div>
							</Grid>
							<Grid item xs={4}>
								<div className="company-logo" >
									<Grid container spacing={2} align="right">
										<Grid item xs={8.5}>
											<Tooltip title="რჩეულებში დამატება" arrow>
												<IconButton href="#" className='header-icon' ><IconStar /></IconButton>
											</Tooltip>
										</Grid>
										<Grid item xs={3.5} >
											<img src={paySummary.providerLogo} />
										</Grid>
									</Grid>
								</div>
							</Grid>
						</Grid>
					</div>
					<div className="body" align="center">
						<table>
							<tr className='table-header'>
								<td width="3%" className="font">
									<Checkbox
										defaultChecked
										sx={{ m: 0, p: 0 }}
									/>
								</td>
								<td width="" className="font">
									სერვისი
								</td>
								<td width="" className="font">
									დეტალები
								</td>
								<td align='center' width="10%" className="font">
									პაკეტი
								</td>
								<td align='center' width="8%" className="font">
									ფასი
								</td>
								<td align='center' width="8%" className="font">
									ბალანსი
								</td>
								<td align='center' width="10%" className="font">
									თანხა
								</td>
							</tr>
							{payDetails.map((option) => (
								<tr>
									<td>
										{' '}
										<Checkbox
											defaultChecked
											sx={{ m: 0, p: 0 }}
										/>
									</td>
									<td>{option.service}</td>
									<td>{option.details}</td>
									<td align='center'>{option.package}</td>
									<td align='center'>{option.cost} {paySummary.currency}</td>
									<td align='center'>{option.balance} {paySummary.currency}</td>
									<td>
										<TextField
											id="outlined-basic"
											defaultValue={option.summary}
											fullWidth
											variant="outlined"
											size="small"
											textField
											className="inputRounded"
										/>
									</td>
								</tr>
							))}
						</table>
						<Grid container spacing={2}>
							<Grid item xs={7.2} align='left'>
								<div className='company-info'>
									<h1 className='company-info-header'>მიმღები:</h1>
									<Typography whiteSpace="pre-line">
										{paySummary.providerDetails}
									</Typography>
								</div>
							</Grid>
							<Grid item xs={4.8}>
								<div align="left">
									<br></br>
									<FormControl fullWidth size="small">
										<InputLabel id="demo-simple-select-label">
											გადახდის მეთოდი
										</InputLabel>
										<Select
											labelId="demo-select-small"
											id="demo-select-small"
											label="გადახდის მეთოდი"
										>
											<MenuItem>ქართული ბარათით</MenuItem>
											<MenuItem>უცხოური ბარათით</MenuItem>
										</Select>
									</FormControl>
									<br></br>
									<p>სერვისების რაოდენობა: 2 </p>
									<p>გადასახდელი თანხა: 200 ₾ </p>
									<p>საკომისიო: 30 ₾ </p>
									<p>სულ გადასახდელი თანხა: 230 ₾ </p>
								</div>

							</Grid>
						</Grid>

					</div>
					<div className="pay-box-dialog-footer">
						<Grid container spacing={2}>
							<Grid item xs={7.2}>
								<Button
									variant="text"
									sx={{ pl: 0, pr: 0 }}
									className="font"
									fullWidth
									startIcon={<IconShoppingCartPlus />}
								>
									გადახდებში დამატება
								</Button>
							</Grid>
							<Grid item xs={4.8}>
								<Button
									className={'button'}
									fullWidth
									endIcon={<IconCreditCard />}
								>
									გადახდა
								</Button>
							</Grid>
						</Grid>
					</div>
				</div>
			</DialogContentText>
		</DialogContent>
	);
};

export default PayProviderServicesDetails;