import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';

import { IconUser } from '@tabler/icons';
import ErrorIcon from '@mui/icons-material/Error';

import useSignInOut from '../../../hooks/useSignInOut';
import ProfileImage from '../Profile/ProfileImage';

const ProfileDropDown = (props) => {
	const navigate = useNavigate();
	const userDetails = useSelector((state) => state.toolkit.userDetails);

	const [verifyStatus, setVerifyStatus] = useState('');
	const [anchorEl, setAnchorEl] = useState(null);
	const [accountID, setAccountID] = useState(0);
	const openPersonMenu = Boolean(anchorEl);
	const [signIn, signOut] = useSignInOut();

	const accountNames = [
		{
			id: 0,
			legalForm: 'ფ/პ',
			label: `${userDetails.firstName} ${userDetails.lastName}`,
		},
		// { id: 1, legalForm: 'შპს', label: 'კომპანია 1', logo: false },
		// { id: 2, legalForm: 'ი/მ', label: 'კომპანია 2', logo: false },
		// { id: 3, legalForm: 'სს', label: 'კომპანია 3', logo: false },
	];

	const handleProfileClick = () => {
		handlePersonClose();
		navigate('/profile');
	};

	const handleSignOutClick = async () => {
		handlePersonClose();
		await signOut();
		await signOut();
		navigate('/');
	};

	const handlePersonClose = () => {
		setAnchorEl(null);
	};

	const handlePersonClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleAccountSelect = (e) => {
		setAccountID(e);
		handlePersonClose();
	};

	useEffect(() => {
		switch (userDetails.verifyStatus) {
			case 1:
				setVerifyStatus({ severity: '#81c784', text: ' შემოწმებული ' });
				break;
			case 2:
				setVerifyStatus({ severity: '#e57373', text: ' უარყოფილი ' });
				break;
			case 0:
				setVerifyStatus({ severity: '#ffb74d', text: ' მოლოდინში ' });
				break;
			case 3:
				setVerifyStatus({ severity: '#ffb74d', text: ' განხილვის პროცესში ' });
				break;
			case -1:
				setVerifyStatus({ severity: '#ffb74d', text: ' შეუმოწმებელი ' });
				break;
			default:
				setVerifyStatus({ severity: '#ffb74d', text: ' დასაზუსტებელი ' });
		}
	}, [userDetails]);

	return (
		<>
			<IconButton
				aria-controls={openPersonMenu ? 'person-menu' : undefined}
				onClick={handlePersonClick}
				// disableElevation
				disableRipple
				size="small"
				sx={{
					ml: 1,
					'&.MuiButtonBase-root:hover': {
						bgcolor: 'transparent',
					},
				}}
			>
				{userDetails.verifyStatus === 1 ? (
					<ProfileImage
						profileImage={userDetails.avatar}
						uID={userDetails.uID}
						picsize={40}
					/>
				) : (
					<div
						className={'avatar-picture'}
						sx={{
							'&.MuiButtonBase-root:hover': {
								bgcolor: 'transparent',
							},
						}}
					>
						<IconUser />
					</div>
				)}
				<div className={'avatar'} align="left">
					{userDetails.verifyStatus == 1 ? (
						<div className={'text'}>
							{/* <div className={'status'}>
								{
									accountNames.find((element) => element.id === accountID)
										.legalForm
								}
							</div> */}
							<div className={'name'}>
								<Typography className={'nameStatus'}>
									{
										accountNames.find((element) => element.id === accountID)
											.label
									}
								</Typography>
							</div>
						</div>
					) : (
						<div className={'text'}>
							<div className={'status'}>სტატუსი</div>
							<div className={'name'}>
								<Typography
									className={'nameStatus'}
									color={verifyStatus.severity}
								>
									{verifyStatus.text}
								</Typography>
							</div>
						</div>
					)}
					<div className={'drop'}>
						<KeyboardArrowDownIcon />
					</div>
				</div>
			</IconButton>
			<Menu
				id="person-menu"
				anchorEl={anchorEl}
				open={openPersonMenu}
				onClose={handlePersonClose}
				MenuListProps={{ 'aria-labelledby': 'person-menu' }}
				className={'profileDrop'}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem className={'profileDrop2'}>
					<ListItemIcon>
						<ErrorIcon fontSize="small" />
					</ListItemIcon>
					<Typography className={'nameStatus'} color={verifyStatus.severity}>
						{verifyStatus.text}
					</Typography>
				</MenuItem>
				<Divider />
				{userDetails.verifyStatus == 1 &&
					accountNames.map((option) => (
						<MenuItem
							key={option.id}
							className={'profileDrop'}
							onClick={() => handleAccountSelect(option.id)}
						>
							<IconUser className="company-avatar" />
							{option.legalForm} {option.label}
						</MenuItem>
					))}
				<Divider />
				<MenuItem onClick={handleProfileClick} className={'profileDrop2'}>
					<ListItemIcon>
						<Settings fontSize="small" />
					</ListItemIcon>
					პროფილის მართვა
				</MenuItem>
				<MenuItem onClick={handleSignOutClick} className={'profileDrop2'}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					გამოსვლა
				</MenuItem>
			</Menu>
		</>
	);
};

export default ProfileDropDown;
