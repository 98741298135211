import React from 'react';

import { 
	IconButton, 
} from '@mui/material';

import { IconDotsVertical, IconSettings } from '@tabler/icons';

const DocAgreements = () => {
	return (
		<div className='service-card'>
			<div className='service-card-header'>
				<span className='font fs-14'>მიღება ჩაბარება</span>
			</div>
			<div className='service-card-body'>
			<table>
					<tr className='table-header'>
						<td width="" className="font">ID</td>
						<td width="" className="font">დასახელება</td>
						<td width="" className="font">თარიღი</td>
						<td align='center' className="font">სტატუსი</td>
						<td align='right' className="font">
							<IconButton aria-label="delete">
								<IconSettings />
							</IconButton>
						</td>
					</tr>
					<tr>
						<td>37492647829 </td>
						<td>ინტერნეტის ინსტალაცია</td>
						<td>10/09/2022</td>
						<td align='center'>აქტიური</td>
						<td align='right'>
							<IconButton aria-label="delete">
								<IconDotsVertical />
							</IconButton>
						</td>
					</tr>
					<tr>
						<td>37492647829 </td>
						<td>ინტერნეტის ინსტალაცია</td>
						<td>10/09/2022</td>
						<td align='center'>აქტიური</td>
						<td align='right'>
							<IconButton aria-label="delete">
								<IconDotsVertical />
							</IconButton>
						</td>
					</tr>
					<tr>
						<td>37492647829 </td>
						<td>ინტერნეტის ინსტალაცია</td>
						<td>10/09/2022</td>
						<td align='center'>აქტიური</td>
						<td align='right'>
							<IconButton aria-label="delete">
								<IconDotsVertical />
							</IconButton>
						</td>
					</tr>
				</table>
			</div>
		</div>
	);
};

export default DocAgreements;