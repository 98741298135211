import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setEasyRegistration } from './../../../toolkitRedux/toolkitReducer';

import ReCAPTCHA from 'react-google-recaptcha';

import { colors, Dialog, Grid, Paper, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

import EasyRegInputPhone from './EasyRegInputPhone';
import EasyRegInputCode from './EasyRegInputCode';

import mainLogo from '../../../assets/images/ipsg_logo_v1.png';
import loginCover from '../../../assets/images/login-bg.png';
// import style from '../../../assets/css/style.css';

const EasyRegistration = () => {
	const easyRegistrationOpen = useSelector(
		(state) => state.toolkit.easyRegistration
	);

	const dispatch = useDispatch();
	const recaptchaRef = React.createRef();
	const [methodValue, setMethodValue] = useState();
	const [step, setStep] = useState(1);

	const StepPage = () => {
		if (step === 1) return <EasyRegInputPhone />;
		if (step === 2) return <EasyRegInputCode />;
	};

	const handleClose = () => {
		setStep(1);
		dispatch(setEasyRegistration(false));
	};

	const handleBack = () => {
		if (step === 1) handleClose();
		if (step === 2) setStep(1);
	};

	return (
		<Dialog
			maxWidth="md"
			open={easyRegistrationOpen}
			PaperProps={{ style: { borderRadius: 6, width:900 } }}
		>
			<Grid container justifyContent="center" spacing={1} className={'auth'}>
				<Grid item xs={6}>
					<Paper sx={{ boxShadow: 'none' }} className={'cover'}>
						<div>
							{/* <img src={loginCover} width="100%" /> */}
						</div>
					</Paper>
				</Grid>
				<Grid item md={6}>
					<Grid container sx={{ pt: 3, pl: 3, pr: 3 }}>
						<Grid item xs={6}>
							{step === 2 && (
								<IconButton variant="contained" onClick={handleBack}>
									<ArrowBackIcon />
								</IconButton>
							)}
						</Grid>
						<Grid
							item
							xs={6}
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							<IconButton variant="contained" onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<Paper sx={{ boxShadow: 'none' }}>
						{step === 1 && (
							<EasyRegInputPhone
								setStep={setStep}
								setMethodValue={setMethodValue}
							/>
						)}
						{step === 2 && (
							<EasyRegInputCode
								setStep={setStep}
								methodValue={methodValue}
								handleClose={handleClose}
							/>
						)}
						{/* <StepPage /> */}
					</Paper>
				</Grid>
			</Grid>
		</Dialog>
	);
};

export default EasyRegistration;
