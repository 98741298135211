import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { IconCircleCheck } from '@tabler/icons';

const ProfileValueChangeSuccess = () => {
	const navigate = useNavigate();
	useEffect(() => {
		const interval = setInterval(() => {
			navigate('/profile/data');
		}, 5000);
		return () => clearInterval(interval);
	}, []);
	return (
		<div>
			<h1 className="text-success">
				<IconCircleCheck width="100" height="100" />
			</h1>
			<p className="text-secondary font">მონაცემები წარმატებით შეიცვალა</p>
		</div>
	);
};

export default ProfileValueChangeSuccess;
