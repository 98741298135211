import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFetching } from '../../../hooks/useFetching';
import ApiService from '../../../API/ApiService';
import { useEffect } from 'react';

import { Box, Grid, Container } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { setMenuSelected } from '../../../toolkitRedux/toolkitReducer';

import Footer from './../Layouts/Footer';
import Header from '../Layouts/Header';
import BodyFrame from '../Layouts/BodyFrame';

import DynamicPageAbout from './DynamicPages/DynamicPageAbout';
import DynamicPageTermsOfService from './DynamicPages/DynamicPageTermsOfService';
import DynamicPageContact from './DynamicPages/DynamicPageContact';
import DynamicPageFaq from './DynamicPages/DynamicPageFaq';

import './DynamicPages.css';

const DynamicPages = () => {
	const params = useParams();
	const dispatch = useDispatch();

	const [pageContent, setPageContent] = useState('');
	const menuSelected = useSelector((state) => state.toolkit.menuSelected);

	const [fetching, isLoading, error] = useFetching(async (param) => {
		const response = await ApiService.loadPage(param);
		return response;
	});

	const loadPage = async () => {
		const request = { page: params.page };
		const response = await fetching(request);
		if (response.data.success === 'success') {
			setPageContent(response.data.data.content);
		} else {
			setPageContent('');
			// alert(response.data.reason);
		}
	};

	useEffect(() => {
		loadPage();
		// console.log(params.page);
	}, [params.page]);

	useEffect(() => {
		dispatch(setMenuSelected(params.page));
	}, []);

	return (
		<>
			<Header />
			<BodyFrame />
			<div className="page">
				{/* <DynamicPageAbout/> */}
				{/* <DynamicPageTermsOfService/> */}
				{/* <DynamicPageContact/> */}
				{/* <DynamicPageFaq/> */}
				<div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
			</div>
			<Footer />
			<Backdrop open={isLoading}>
				<CircularProgress size={100} />
			</Backdrop>
		</>
	);
};

export default DynamicPages;
