import React from 'react';

import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material';

const ServiceProviders = () => {

	const serviceProviders = [
		{
			id: 1,
			idn: 'telecom1',
			name: 'ტელეკომ 1',
		},
		{
			id: 2,
			idn: 'datacomm',
			name: 'დატაკომი',
		}
	];

	return (
		<FormControl fullWidth size="small" className='br-6'>
			<InputLabel id="demo-simple-select-label">პროვაიდერი</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				label="პროვაიდერი"
			>
				{serviceProviders.map((option) =>
					<MenuItem key={option.id}>{option.name}</MenuItem>
				)}
			</Select>
		</FormControl>
	);
};

export default ServiceProviders;