import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import logo2 from '../../../assets/images/inter-pay-logo.png';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Link from '@mui/material/Link';
import {
	IconStar,
	IconMail,
	IconShoppingCart,
	IconDeviceMobileMessage,
} from '@tabler/icons';

import { Grid, Badge } from '@mui/material';
import { CssBaseline, AppBar, Toolbar } from '@mui/material';

import DarkModeButton from '../Buttons/DarkModeButton';
import ProfileDropDown from './ProfileDropDown';

import './AuthorizedHeader.css';

const AuthorizedHeader = (props) => {
	const navigate = useNavigate();
	const unreadedMessages = useSelector(
		(state) => state.toolkit.unreadedMessages
	);

	const handleMailClick = () => {
		navigate('/messages');
	};

	const handleSMScClick = () => {
		navigate('/myservices/smsc');
	};

	const sxbr = `backgroundColor: '#6381a8', color: 'white'`;

	return (
		<AppBar color="inherit" elevation={0}>
			<Container>
				<Toolbar>
					<Grid container spacing={2} columns={6}>
						<Grid
							item
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							<Link href="/" className={'logo'}>
								<img src={logo2} height="50" />
							</Link>
						</Grid>
						<Grid item xs></Grid>
						<Grid
							item
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
							className="auth-right"
						>
							<Tooltip title="რჩეული გადახდები" arrow>
								<IconButton href="#" className="header-icon">
									<IconStar />
								</IconButton>
							</Tooltip>
							<Tooltip title="გადახდები" arrow>
								<IconButton href="/cart" className="header-icon">
									<IconShoppingCart />
								</IconButton>
							</Tooltip>
							<Tooltip title="SMS ცენტრი" arrow>
								<IconButton onClick={handleSMScClick} className={'header-icon'}>
									<Badge badgeContent={unreadedMessages}>
										<IconDeviceMobileMessage />
									</Badge>
								</IconButton>
							</Tooltip>
							<Tooltip title="საფოსტო ყუთი" arrow>
								<IconButton onClick={handleMailClick} className={'header-icon'}>
									<Badge badgeContent={unreadedMessages}>
										<IconMail />
									</Badge>
								</IconButton>
							</Tooltip>
							<DarkModeButton />
							<ProfileDropDown />
						</Grid>
					</Grid>
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export default AuthorizedHeader;
