import ApiService from './ApiService';

export default class UserLoad extends ApiService {
	static async userLoad(ntoken) {
		const request = { function: 'getUserData', token: ntoken };
		let response = false;
		try {
			response = await this.userApi(request);
		} catch (e) {
			response = e.message;
		} finally {
			localStorage.setItem('userDetails', JSON.stringify(response.data.data));
		}
		return response.data;
	}

	static async userDocument(ntoken) {
		const request = { function: 'getUserDocument', token: ntoken };
		let response = false;
		try {
			response = await this.userApi(request);
		} catch (e) {
			response = e.message;
		} finally {
			localStorage.setItem('userDocument', JSON.stringify(response.data.data));
		}
		return response.data;
	}

	static async identoGetResults(ntoken, npars) {
		let response = false;
		const request = {
			token: ntoken,
			function: 'identoGetResults',
			pars: npars,
		};
		try {
			response = await this.userApi(request);
		} catch (e) {
			response = e.message;
		} finally {
		}
		return response.data;
	}

	static async verifyOnDuplicates(ntoken, npars) {
		let response = false;
		const request = {
			token: ntoken,
			function: 'verifyOnDuplicates',
			pars: npars,
		};
		try {
			response = await this.userApi(request);
		} catch (e) {
			response = e.message;
		} finally {
		}
		return response.data;
	}
}
