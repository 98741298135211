import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import ApiService from '../API/ApiService';
import { useFetching } from './useFetching';
import { setToken } from '../toolkitRedux/toolkitReducer';
import { setIsLoading } from '../toolkitRedux/toolkitReducer';
import { setUserDetails } from '../toolkitRedux/toolkitReducer';

const useSignInOut = () => {
	const dispatch = useDispatch();
	const [cookies, setCookie, removeCookie] = useCookies(['token', 'device-id']);

	const [loadData, isDataLoading, error] = useFetching(async (param) => {
		const response = await ApiService.userLogin(param);
		return response;
	});

	const signIn = async (userData) => {
		const username = userData.username;
		const password = userData.password;
		const request = { username: username, password: password };
		const resp = await loadData(request);
		// console.log(resp);
		if (resp.data.success === 'success') {
			dispatch(setUserDetails(resp.data.userDetails));
			// localStorage.setItem('userDetails', JSON.stringify(resp.data.userDetails));
			const newToken = resp.data.token;
			const expires = new Date();
			expires.setDate(expires.getDate() + 30);
			setCookie('token', newToken, { expires: expires });
			dispatch(setToken(newToken));
		}
		return resp;
	};

	const signOut = async () => {
		localStorage.clear();
		removeCookie('token');
		dispatch(setToken(false));
	};

	useEffect(() => {
		dispatch(setIsLoading(isDataLoading));
	}, [isDataLoading]);

	return [signIn, signOut];
};

export default useSignInOut;
