import { useState } from "react";

export const useFetching = (callback) => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	let ret;

	const fetching = async (...args) => {
		try {
			setIsLoading(true);
			ret = await callback(...args);
		} catch (e) {
			setError(e.message);
		} finally {
			setIsLoading(false);
		}
		return ret;
	};
	return [fetching, isLoading, error];
};
