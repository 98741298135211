import React from 'react';

import { Grid, Container } from '@mui/material';

import { IconCreditCard } from '@tabler/icons';

import Header from '../Layouts/Header';
import BodyFrame from '../Layouts/BodyFrame';
import HomeCover from './HomeCover';
import Footer from '../Layouts/Footer';

import './HomePage.css';

const HomePage = (props) => {
	return (
		<>
			<Header />
			<BodyFrame />
			<div className={'page'}>
				<HomeCover />

				<Container>
					<div className='home-page-section'>
						<Grid container spacing={4}>
							{/* <Grid item xs={1.5} align='center'></Grid> */}
							<Grid item xs={12} align='center' >
								<h1 className='font-bold fs-42 text-primary'>რატომ INTERPAY.GE?</h1>
								<p className='text-secondary font fs-16'>innterpay.ge სწრაფი და მარტივი გადახდების პლატფორმაა, სადაც 1000-ზე მეტი სერვისის ონლაინ გადახდაა შესაძლებელი. გადაიხადე კომუნალური თუ სხვა ტიპის გადასახადები ონლაინ, ყველაზე მარტივად და კომფორტულად.</p>
							</Grid>
							{/* <Grid item xs={1.5} align='center'></Grid> */}
							<Grid item xs={5}>
								<img src="https://html.themexriver.com/Saasio/assets/img/app-landing-2/brands/brands-logo.png" width='450' alt="" />
							</Grid>
							<Grid item xs={7}>
								<div className='home-page-box'> 
									<h1 className='font-bold fs-18 text-primary'>გადაიხადე ნებისმიერი ქართული ბანკის ბარათით</h1>
									<p className='text-secondary'>innterpay.ge-ზე ონლაინ გადახდა  შეგიძლია როგორც საქართველოს, ისე სხვა ნებისმიერი ბანკის ბარათით</p>
								</div>
								<div className='home-page-box'>
									<h1 className='font-bold fs-18 text-primary'>შესრულებული გადახდა პროვაიდერთან აისახება მომენტალურად</h1>
									<p className='text-secondary'>გადახდილი თანხის ასახვა ნებისმიერ სერვისზე, მათ შორის საბანკო ანგარიშებზე, მოხდება მომენტალურად</p>
								</div>
								<div className='home-page-box'>
									<h1 className='font-bold fs-18 text-primary'>დაიმახსოვრე ხშირად შესრულებული გადახდები</h1>
									<p className='text-secondary'>დაამატე „ჩემს გადახდებში“ შენთვის სასურველი სერვისები, კიდევ უფრო მარტივად შეამოწმე და გადაიხადე დავალიანება</p>
								</div>
							</Grid>
						</Grid>
					</div>
				</Container>
			</div>
			<Footer />
		</>
	);
};
export default HomePage;
