import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { IconSearch } from '@tabler/icons';
import { IconShoppingCartPlus } from '@tabler/icons';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { IconWorldUpload } from '@tabler/icons';
import { IconStar } from '@tabler/icons';
import { IconListCheck } from '@tabler/icons';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { IconCreditCard } from '@tabler/icons';
import { IconPlus } from '@tabler/icons';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


import telecom1 from '../../../assets/images/telecom1.png';
import datacomm from '../../../assets/images/datacomm.png';
import PayProviderServices from './PayProviderServices';

const PayFavorites = () => {
	const [moreDialogOpen, setMoreDialogOpen] = React.useState(false);

	const handleClickOpen = () => {
		setMoreDialogOpen(true);
	};

	const handleClose = () => {
		setMoreDialogOpen(false);
	};

	const paySummary = [
		{
			provider: 'ტელეკომ1',
			providerLogo: telecom1,
			providerDetails: 'შპს ტელეკომ 1\nს/კ: 412412412\nტელ.: +995323100100\nელ-ფოსტა: info@telecom1.ge',
			customer: 'ა.ქ.',
			currency: '₾',
			servicesCount: 4,
			summary: 200,
			commision: 30,
			total: 230,
			details: [
				{
					id: 2371,
					service: 'ინტერნეტი',
					details: 'თბილისი, ვაკე-საბურთალოს, მე4 მიკრო რაიონი მე5 შესახვევი კვარტალი 2 ბინა 4/6',
					package: 'Silver',
					cost: 50,
					balance: 0,
					summary: 50,
				},
				{
					id: 3421,
					service: 'ტელეფონი',
					details: 'დედოფლისწყარო',
					package: 'Gold',
					cost: 150,
					balance: 50,
					summary: 100,
				},
				{
					id: 3441,
					service: 'ტელევიზია',
					details: 'თბილისი',
					package: 'Silver',
					cost: 50,
					balance: 50,
					summary: 0,
				},
				{
					id: 3462,
					service: 'ინსტალაცია - ინტერნეტი',
					details: 'ქუთაისი',
					package: '-',
					cost: 50,
					balance: 0,
					summary: 50,
				},
			],
		},
		{
			provider: 'დატაკომი',
			providerLogo: datacomm,
			providerDetails: 'შპს დატაკომი 1\nს/კ: 333242344\nტელ.: +995431310000\nელ-ფოსტა: info@datacomm.ge',
			customer: 'ა.ლ.',
			currency: '₾',
			servicesCount: 3,
			summary: 100,
			commision: 10,
			total: 110,
			details: [
				{
					id: 5371,
					service: 'ინტერნეტი',
					details: 'ქუთაისი, ჭავჭავაძის 32',
					package: 'Silver',
					cost: 30,
					balance: 0,
					summary: 30,
				},
				{
					id: 5421,
					service: 'ტელეფონი',
					details: 'ქუთაისი, ჭავჭავაძის 32, 282299',
					package: 'Gold',
					cost: 5,
					balance: 10,
					summary: 5,
				},
				{
					id: 5441,
					service: 'ტელევიზია',
					details: 'ქუთაისი, ჭავჭავაძის 32',
					package: 'Silver',
					cost: 15,
					balance: -20,
					summary: 35,
				},
			],
		},
	]

	return (
		<div>
			<Grid item xs={12} sx={{ mb:2 }}>
				<div className={'pay-box'}>
					<div className={''} align="center">
						<Grid container spacing={2}>
							<Grid item xs={11}>
								<TextField
									fullWidth
									className={'inputRounded'}
									size="small"
									label="კომპანიის დასახელება"
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={1}>
								<div className="pt-8 text-primary">
									<IconSearch width="20" />
								</div>
							</Grid>
						</Grid>
					</div>
				</div>
			</Grid>

			<Grid container spacing={2}>
				{paySummary.map((option) =>
					<PayProviderServices paySummary={option} />
				)}
			</Grid>
		</div>
	);
};

export default PayFavorites;