import React from 'react';

const ReciptsPayments = () => {
	return (
		<div className='service-card'>
			<div className='service-card-header'>
				გადახდები
			</div>
			<div className='service-card-body'>
				გადახდები
			</div>
		</div>
	);
};

export default ReciptsPayments;