const topMenu = [
	{
		id: "payments",
		target: "/payments",
		activeClass: "item-active",
		inactiveClass: "item",
		itemText: "სერვისების გადახდა",
	},
	{
		id: "myServices",
		target: "/myservices/current",
		activeClass: "item-active",
		inactiveClass: "item",
		itemText: "ჩემი სერვისები",
	},
	// {
	// 	id: "paymentHistory",
	// 	activeClass: "item-active",
	// 	inactiveClass: "item",
	// 	itemText: "გადახდების ისტორია",
	// },
	{
		id: "messages",
		target: "/messages",
		activeClass: "item-active",
		inactiveClass: "item",
		itemText: "საფოსტო ყუთი",
	},
];

export default topMenu;
