import React from 'react';

import { 
	IconButton, 
	Grid, 
	FormControl, 
	InputLabel, 
	Select, 
	MenuItem, 
	TextField ,
} from '@mui/material';

import { IconDotsVertical, IconEye } from '@tabler/icons';

const DocInvoices = () => {
	return (
		<div className='service-card'>
			<div className='service-card-header'>
				<span className='font fs-14'>ინვოისი</span>
			</div>
			<div className='service-card-body'>
				<div className={''} align="center">
					<Grid container spacing={2} sx={{ mb:3 }}>
						<Grid item xs={3}>
							<TextField
								fullWidth
								className={'inputRounded'}
								size="small"
								label="ID"
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								className={'inputRounded'}
								size="small"
								label="დაწყება"
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								className={'inputRounded'}
								size="small"
								label="დასრულება"
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={3}>
							<FormControl fullWidth size="small" className='br-6'>
								<InputLabel id="demo-simple-select-label">სტატუსი</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value="სს"
									label="სტატუსი"
									className={'inputRounded'}
								>
									<MenuItem value={10}>აქტიური</MenuItem>
									<MenuItem value={20}>გაუქმებული</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</div>
				<table>
					<tr className='table-header'>
						<td width="" className="font">ID</td>
						<td width="" className="font">თარიღი</td>
						<td align='center' className="font">სტატუსი</td>
						<td align='center' className="font">ბარათი</td>
						<td align='center' className="font">ფასი</td>
						<td align='right' className="font">ნახვა</td>
					</tr>
					<tr>
						<td>37492647829 </td>
						<td>10/09/2022</td>
						<td align='center'>აქტიური</td>
						<td align='center'>**** **** **** 4738</td>
						<td align='center'>50 ₾</td>
						<td align='right'>
							<IconButton aria-label="delete">
								<IconEye />
							</IconButton>
						</td>
					</tr>
					<tr>
						<td>37492647829 </td>
						<td>10/09/2022</td>
						<td align='center'>აქტიური</td>
						<td align='center'>**** **** **** 4738</td>
						<td align='center'>50 ₾</td>
						<td align='right'>
							<IconButton aria-label="delete">
								<IconEye />
							</IconButton>
						</td>
					</tr>
					<tr>
						<td>37492647829 </td>
						<td>10/09/2022</td>
						<td align='center'>აქტიური</td>
						<td align='center'>**** **** **** 4738</td>
						<td align='center'>50 ₾</td>
						<td align='right'>
							<IconButton aria-label="delete">
								<IconEye />
							</IconButton>
						</td>
					</tr>
				</table>
			</div>
		</div>
	);
};

export default DocInvoices;