import React from 'react';

import MyServices from './MyServices';

import Footer from '../Layouts/Footer';
import Header from '../Layouts/Header';
import BodyFrame from '../Layouts/BodyFrame';

const index = () => {
	return (
		<>
			<Header />
			<BodyFrame />
			<div className={'page'}>
				<MyServices />
			</div>
			<Footer />
		</>
	);
};

export default index;