import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setUserDetails } from '../../../toolkitRedux/toolkitReducer';

import { FileUploader } from 'react-drag-drop-files';

import Moment from 'react-moment';

import ApiService from '../../../API/ApiService';
import UserLoad from '../../../API/UserLoad';
import { useFetching } from '../../../hooks/useFetching';

import { Grid, Button, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { IconUser, IconCloudUpload } from '@tabler/icons';
import ProfileImage from './ProfileImage';

import VerifyFrame from './VerifyFrame';

const genderList = [
	{ id: 'm', name: 'მამრობითი' },
	{ id: 'f', name: 'მდედრობითი' },
];

const gender = { M: 'მამრობითი', F: 'მდედრობითი' };
const doc_type = {
	id: 'პირადობის მოწმობა',
	passport: 'პასპორტი',
	driver_license: 'მართვის მოწმობა',
};

const ProfileData = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userDetails = useSelector((state) => state.toolkit.userDetails);
	const token = useSelector((state) => state.toolkit.token);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [verifyFrameOpen, setVerifyFrameOpen] = useState(false);

	const [fetching, isLoading, error] = useFetching(async (param) => {
		const response = await ApiService.uploadFile(param);
		return response;
	});

	const [userDocument, setUserDocument] = useState(false);
	// const [verifyDialog, setVerifyDialog] = useState('');

	const handleAlertClose = () => {
		setAlertOpen(false);
	};

	const loadUserDocuemnt = async () => {
		const response = await UserLoad.userDocument(token);
		if (response.success === 'success') setUserDocument(response.data);
	};

	const loadUserDetails = async () => {
		const response = await UserLoad.userLoad(token);
		if (response.success === 'success') {
			dispatch(setUserDetails(response.data));
		}
	};

	const handleStartVerifycation = () => {
		console.log('userDocument', userDocument);
		setVerifyFrameOpen(true);
		// setVerifyDialog(
		// 	<VerifyFrame setAlertOpen={setAlertOpen} setAlertText={setAlertText} />
		// );
	};

	const handleAddPhone = () => {
		navigate('/profile/addphone');
	};

	const handleAddEmail = () => {
		navigate('/profile/addemail');
	};

	const handleAvatarUpload = async (obj, objName) => {
		const conf = { token: token, uID: userDetails.uID, objName: objName };
		const formData = new FormData();
		formData.append('myfile', obj);
		formData.append('params', JSON.stringify(conf));
		const response = await fetching(formData);
		if (response.data.success === 'success') {
			loadUserDetails();
		}
	};

	useEffect(() => {
		// loadUserDetails();
		loadUserDocuemnt();
	}, []);

	const alertAction = (
		<React.Fragment>
			<Button color="secondary" size="small" onClick={handleAlertClose}>
				{' '}
			</Button>
			<IconButton size="small" aria-label="close" onClick={handleAlertClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	return (
		<div className={'profile-page'}>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<div className={'profile-page-picture'} align="center">
						{userDetails.verifyStatus === 1 ? (
							<ProfileImage picsize={200} />
						) : (
							<IconUser className={'profile-page-picture-icon'} />
						)}
						<div>
							<Button
								variant="outlined"
								sx={{ mt: 3 }}
								className="font-bold"
								startIcon={<IconCloudUpload />}
								onClick={handleAvatarUpload}
							>
								<FileUploader
									handleChange={(obj) => handleAvatarUpload(obj, 'avatar')}
									types={['JPG', 'JPEG', 'PNG']}
									multiple={false}
									maxSize={1}
								>
									სურათის ატვირთვა
								</FileUploader>
							</Button>
						</div>
					</div>
				</Grid>
				<Grid item xs={8}>
					<div className="profile-page-content">
						<li>
							<span>სახელი:</span>{' '}
							{userDetails.verifyStatus === 1
								? userDocument.local_first_name
								: 'შეუმოწმებელი'}
						</li>
						<li>
							<span>გვარი:</span>{' '}
							{userDetails.verifyStatus === 1
								? userDocument.local_last_name
								: 'შეუმოწმებელი'}
						</li>
						<li>
							<span>სქესი:</span>{' '}
							{userDetails.verifyStatus === 1
								? gender[userDocument.sex]
								: 'შეუმოწმებელი'}
						</li>
						<li>
							<span>დაბადების თარიღი:</span>{' '}
							{userDetails.verifyStatus === 1 ? (
								<Moment format="DD/MM/YYYY">
									{userDocument.birthday_time}
								</Moment>
							) : (
								'შეუმოწმებელი'
							)}
						</li>
						{/* <li>
							<span>დაბადების ადგილი:</span>{' '}
							{userDocument ? userDocument.birth_place : 'შეუმოწმებელი'}
						</li> */}
						<li>
							<span>მოქალაქეობა:</span>{' '}
							{userDetails.verifyStatus === 1
								? userDocument.citizenship
								: 'შეუმოწმებელი'}
						</li>
						<li>
							<span>პირადი ნომერი:</span>{' '}
							{userDetails.verifyStatus === 1
								? userDocument.personal_number
								: 'შეუმოწმებელი'}
						</li>
						<li>
							<span>დოკუმენტი:</span>{' '}
							{userDetails.verifyStatus === 1
								? doc_type[userDocument.document_type] +
								  ' / ' +
								  userDocument.document_number
								: 'შეუმოწმებელი'}
						</li>
						<li>
							<span>მოქმედების ვადა:</span>{' '}
							{userDetails.verifyStatus === 1 ? (
								<Moment format="DD/MM/YYYY">
									{userDocument.document_expires_time}
								</Moment>
							) : (
								'შეუმოწმებელი'
							)}
						</li>
						<li>
							{userDetails?.phone ? (
								<span>მობილური: {userDetails.phone}</span>
							) : (
								<Button variant="contained" onClick={handleAddPhone}>
									მობილურის დამატება
								</Button>
							)}
						</li>
						<li>
							{userDetails?.email ? (
								<span>ელ-ფოსტა: {userDetails.email}</span>
							) : (
								<Button variant="contained" onClick={handleAddEmail}>
									ელ-ფოსტის დამატება
								</Button>
							)}
						</li>
					</div>
					{userDetails.verifyStatus !== 1 && (
						<Button
							variant="contained"
							fullWidth
							className="font mt-2"
							onClick={handleStartVerifycation}
						>
							აქტივაცია
						</Button>
					)}
				</Grid>
			</Grid>
			<VerifyFrame
				setVerifyFrameOpen={setVerifyFrameOpen}
				verifyFrameOpen={verifyFrameOpen}
				setAlertOpen={setAlertOpen}
				setAlertText={setAlertText}
			/>
			<Snackbar
				open={alertOpen}
				autoHideDuration={30000}
				onClose={handleAlertClose}
				action={alertAction}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert severity="error">{alertText}</Alert>
			</Snackbar>
		</div>
	);
};

export default ProfileData;
