import React, { } from 'react';

import {
	IconButton,
	Grid,
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	TextField,
} from '@mui/material';

import { IconDotsVertical, IconUserPlus, IconSettings } from '@tabler/icons';

const MyContacts = () => {

	const [newContactDialogOpen, setNewContactDialogOpen] = React.useState(false);

	const newContactClickOpen = () => {
		setNewContactDialogOpen(true);
	};

	const newContactClose = () => {
		setNewContactDialogOpen(false);
	};

	return (
		<div className='service-card'>
			<div className='service-card-header'>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<div className='service-card-header-title'>კონტაქტები</div>
					</Grid>
					<Grid item xs={6} align='right'>
						<Button
							variant="outlined"
							startIcon={<IconUserPlus />}
							size="small"
							className='button'
							onClick={newContactClickOpen}
						>
							კონტაქტის დამატება
						</Button>
					</Grid>
				</Grid>
			</div>
			<div className='service-card-body'>
				<table>
					<tr className='table-header'>
						<td width="" className="font">სახელი გვარი</td>
						<td width="" className="font">ტელეფონს ნომერი</td>
						<td width="" className="font" align='right'>
							<IconButton aria-label="setting" size="small">
								<IconSettings />
							</IconButton>
						</td>
					</tr>
					<tr>
						<td>ავთანდილ ქარქაშაძე </td>
						<td>+995596920202</td>
						<td align='right'>
							<IconButton aria-label="setting" color="primary" size="small">
								<IconDotsVertical />
							</IconButton>
						</td>
					</tr>
					<tr>
						<td>ავთანდილ ლომინაძე </td>
						<td>+995596324567</td>
						<td align='right'>
							<IconButton aria-label="setting" color="primary" size="small">
								<IconDotsVertical />
							</IconButton>
						</td>
					</tr>
					<tr>
						<td>ავთანდილ ლომინაძე </td>
						<td>+995596324567</td>
						<td align='right'>
							<IconButton aria-label="setting" color="primary" size="small">
								<IconDotsVertical />
							</IconButton>
						</td>
					</tr>
					<tr>
						<td>ავთანდილ ლომინაძე </td>
						<td>+995596324567</td>
						<td align='right'>
							<IconButton aria-label="setting" color="primary" size="small">
								<IconDotsVertical />
							</IconButton>
						</td>
					</tr>
					<tr>
						<td>ავთანდილ ლომინაძე </td>
						<td>+995596324567</td>
						<td align='right'>
							<IconButton aria-label="setting" color="primary" size="small">
								<IconDotsVertical />
							</IconButton>
						</td>
					</tr>
				</table>
				<Dialog
					open={newContactDialogOpen}
					onClose={newContactClose}
					PaperProps={{ style: { borderRadius: 6, width: 500 } }}
				>
					<DialogContent>
						<DialogContentText>
							<div className={'service-card-dialog'}>
								<div className="service-card-dialog-header">
									კონტაქტის დამატება
								</div>
								<div className="service-card-dialog-body" align="center">

									<TextField
										id="outlined-basic"
										label="სახელი გვარი"
										fullWidth
										size="small"
										textField
										className="inputRounded"
										variant="outlined"
										sx={{ mb: 3 }}
									/>
									<TextField
										id="outlined-basic"
										label="ტელეფონის ნომერი"
										fullWidth
										size="small"
										textField
										className="inputRounded"
										variant="outlined"
									/>

								</div>
								<div className="service-card-dialog-footer">
									<Button
										className={'button'}
										fullWidth
										startIcon={<IconUserPlus />}
									>
										კონტაქტში დამატება
									</Button>
								</div>
							</div>
						</DialogContentText>
					</DialogContent>
				</Dialog>
			</div>
		</div>
	);
};

export default MyContacts;
