import React, { Suspense, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import './App.css';
import './GirdTable.css';

import DarkMode from './DarkMode';

import MyRoutes from './Routes';

import {
	setToken,
	setUnreadedMessages,
	setUserDetails,
} from '../../toolkitRedux/toolkitReducer';

import { useFetching } from '../../hooks/useFetching';
import ApiService from '../../API/ApiService';

import Analytics from './Analytics';

function App() {
	const styleName = useSelector((state) => state.toolkit.styleName);
	const muiTheme = useSelector((state) => state.toolkit.muiTheme);
	const isLoading = useSelector((state) => state.toolkit.isLoading);
	const [isLoaded, setIsLoaded] = useState(false);

	const [fetching, isDataLoading, error] = useFetching(async (param) => {
		const response = await ApiService.userApi(param);
		return response;
	});

	const dispatch = useDispatch();
	const [cookies, setCookie, removeCookie] = useCookies(['token', 'device-id']);

	const preloads = async () => {
		let ctoken = cookies.token;
		if (ctoken) {
			const pars = { function: 'getUserData', token: ctoken };
			const response = await fetching(pars);
			if (response.data.success === 'success') {
				// localStorage.setItem("userDetails", JSON.stringify(response.data.data));
				// localStorage.setItem("token", ctoken);
				dispatch(setToken(cookies.token));
				dispatch(setUnreadedMessages(response.data.data.unreadedMessages));
				dispatch(setUserDetails(response.data.data));
			} else {
				// localStorage.removeItem("userData");
				// localStorage.removeItem("token");
				dispatch(setUserDetails(false));
				removeCookie('token');
				ctoken = false;
			}
		}
	};

	const theme = React.useMemo(
		() =>
			createTheme({
				palette: {
					mode: muiTheme,
					primary: {
						main: '#6381a8',
						dark: '#335188',
					},
					secondary: {
						main: '#555B6E',
						contrastText: '#fff',
					},
				},
				typography: {
					allVariants: {
						fontFamily: 'FiraGO',
						fontWeight: '800',
						fontSize: '13px',
					},
				},
			}),
		[muiTheme]
	);

	useEffect(() => {
		preloads();
		setIsLoaded(true);
	}, []);

	if (isLoaded)
		return (
			<div className="App">
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
						open={isLoading}
					>
						<CircularProgress size={100} />
					</Backdrop>
					<DarkMode />
					<Analytics />
					<div className={styleName}>
						<Suspense fallback="WAIT">
							<MyRoutes />
						</Suspense>
					</div>
				</ThemeProvider>
			</div>
		);
}
export default App;
