import { IconStar, IconListCheck, IconWorldUpload } from "@tabler/icons";

import PayFavorites from "./PayFavorites";
import PayHistory from "./PayHistory";
import PayTelecom from "./PayTelecom";
import PayProviderShow from "./PayProviderShow";

const icons = {
	IconStar,
	IconListCheck,
	IconWorldUpload,
};

const menuItems = [
	{
		id: "telecom",
		title: "ინტერნეტი ტელეფონი ტელევიზია",
		type: "item",
		icon: icons.IconWorldUpload,
		target: "/payments/telecom",
		onClick: <PayTelecom />,
	},
	{
		id: "providers",
		title: "ინტერნეტი ტელეფონი ტე. შედეგი",
		type: "item",
		icon: icons.IconWorldUpload,
		target: "/payments/providers",
		onClick: <PayProviderShow />,
	},
	{
		id: "favorites",
		title: "რჩეული გადახდები",
		type: "item",
		icon: icons.IconStar,
		target: "/payments/favorites",
		onClick: <PayFavorites />,
	},
	{
		id: "history",
		title: "გადახდების ისტორია",
		type: "item",
		icon: icons.IconListCheck,
		target: "/payments/history",
		onClick: <PayHistory action="phone" />,
	},
];

const pages = {
	telecom: {
		title: "ინტერნეტი ტელეფონი ტელევიზია",
		target: <PayTelecom />,
	},
	providers: {
		title: "პროვაიდერები",
		target: <PayProviderShow />,
	},
	favorites: {
		title: "რჩეული გადახდები",
		target: <PayFavorites />,
	},
	history: {
		title: "გადახდების ისტორია",
		target: <PayHistory />,
	},
};

export { menuItems, pages };
