import React from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Container } from '@mui/material';

import { menuItems, pages } from './menu-items';
import MenuGroup from './../MenuList/MenuGroup';
import Navlink from '../Breadcrumbs';


const Profile = () => {
	const params = useParams();
	const pageID = params.id ? params.id : 'data';

	const links = [
		{ title: 'პროფილის მართვა', link: '' },
		{ title: pages[pageID]?.title },
	];

	return (
		<>
			<Navlink links={links} />
			<Container>
				<Grid container spacing={2} align="left" className={'profile'}>
					<Grid item xs={3.7} className={'left-nav'}>
						<div className={'card'}>
							<MenuGroup item={menuItems} current={pageID} pages={pages} />
						</div>
					</Grid>
					<Grid item xs={8.3}>
						<div className={'profile-box'}>{pages[pageID].target}</div>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default Profile;
