import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';

import { useDispatch, useSelector } from 'react-redux';
import { setMenuSelected } from '../../../toolkitRedux/toolkitReducer';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import './menu.css';

import topMenu from './DefaultMenuList';

const DefaultMenu = () => {

	const params = useParams();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const menuSelected = useSelector((state) => state.toolkit.menuSelected);

	const handleMenuClick = (e) => {
		const btnId = e.target.id;
		dispatch(setMenuSelected(btnId));
		const target = btnId === 'home' ? '/' : `/${btnId}`;
		navigate(target);
	};

	const handleCabinetMenuClick = (e) => {
		// const btnId = e.target.id;
		// dispatch(setMenuSelected(btnId));
		dispatch(setMenuSelected(e.id));
		// const target = btnId === 'home' ? '/' : `/cabinet/${btnId}`;
		navigate(e.target);
	};

	useEffect(() => {
		// dispatch(setMenuSelected(params.pageId));
		// console.log('Cabinet PageID:', params.pageId);
	}, []);

	return (
		<div className={'nav'}>
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={10}>
						<Stack
							spacing={2}
							direction="row"
							sx={{ m: 0, p: 0.5 }}
							bgcolor="#6381a8"
						>
							{topMenu.map((option) => (
								<Button
									id={option.id}
									key={option.id}
									onClick={() => handleCabinetMenuClick(option)}
									color="inherit"
									className={menuSelected === option.id ? option.activeClass : option.inactiveClass}
								>
									{option.itemText}
								</Button>
							))}
						</Stack>
					</Grid>
					<Grid item xs={2} align='right' sx={{ pt: 2 }} >
						<div className='lang-group'>
							<a href='' className='lang-active'>ქარ</a>
							<a href='' className='lang'>ENG</a>
							{/* <a href='' className='lang'>РУС</a> */}
						</div>

					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default DefaultMenu;
