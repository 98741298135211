import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails } from '../../../toolkitRedux/toolkitReducer';

import ApiService from '../../../API/ApiService';
import { useFetching } from '../../../hooks/useFetching';
import UserLoad from '../../../API/UserLoad';
import NumberFormat from 'react-number-format';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import CheckIcon from '@mui/icons-material/Check';

const ProfileValueChangeConfirm = (props) => {
	const [uuCode, setUuCode] = useState('');
	const [code, setCode] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const newValues = props.newValues;
	const token = useSelector((state) => state.toolkit.token);
	const messageText = props.messageText
		? props.messageText
		: 'კოდი გამოგზავნილია';

	const dispatch = useDispatch();

	const [featching, isDataLoading, error] = useFetching(async (param) => {
		const response = await ApiService.userApi(param);
		return response;
	});

	const requestCode = async () => {
		// let pars;
		// if (newValues.phone) {
		// 	pars = { phone: newValues.phone };
		// }
		// if (newValues.email) {
		// 	pars = { email: newValues.email };
		// }
		const request = {
			token: token,
			function: 'requestConfirmCode',
			pars: newValues,
		};
		const response = await featching(request);
		if (response?.data && response.data.success === 'success') {
			setUuCode(response.data.uucode);
			// console.log(response.data);
		} else {
			// handle problem
			// setErrorMessage(response.data.reason);
			setErrorMessage('შეფერხება კოდის გამოგზავნისას');
		}
	};

	const updateUserValues = async () => {
		const request = {
			token: token,
			function: 'updateUserValues',
			pars: {
				uuid: uuCode,
				code: code,
				newValues: newValues,
			},
		};
		const response = await featching(request);
		if (response?.data && response.data.success === 'success') {
			// Successfully updated so update userDetails
			const response = await UserLoad.userLoad(token);
			if (response.success === 'success') {
				dispatch(setUserDetails(response.data));
			}
			props.setPage(3);
		} else {
			setErrorMessage(response.data.reason);
		}
	};

	const handleConfirmButton = () => {
		updateUserValues();
	};

	const handleKeyPress = (e) => {
		setErrorMessage('');
		if (e.key === 'Enter' && code > 100000) {
			updateUserValues();
		}
	};

	useEffect(() => {
		setErrorMessage('');
		// Request Confirm Code
		requestCode();
	}, []);

	// console.log('newValues', newValues);

	return (
		<div className={'phone-mail-change-box'}>
			<div className={'phone-mail-change-box-title'}>{messageText}</div>
			<div>
				<NumberFormat
					fullWidth
					className={'inputRounded'}
					format="######"
					size="small"
					customInput={TextField}
					label="ერთჯერადი კოდი"
					variant="outlined"
					onChange={(event) => setCode(event.target.value)}
					onKeyPress={handleKeyPress}
					autoFocus
				/>
			</div>
			{errorMessage}
			<div>
				<Button
					variant="contained"
					className={'button'}
					sx={{ mt: 3 }}
					endIcon={<CheckIcon />}
					fullWidth
					disabled={!(code > 100000)}
					onClick={handleConfirmButton}
				>
					დასრულება
				</Button>
			</div>
		</div>
	);
};

export default ProfileValueChangeConfirm;
