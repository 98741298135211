import React from 'react';

const BodyFrame = () => {
	return (
		<div className='content'>

		</div>
	);
};

export default BodyFrame;