import React from 'react';

import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { Button } from '@mui/material';

import nbgLogo from '../../../assets/images/nbg-logo.png';
import visa from '../../../assets/images/visa-logo.png';
import mc from '../../../assets/images/master-cardl-logo.png';
import ae from '../../../assets/images/american-express-logo.png';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

import { useDispatch, useSelector } from 'react-redux';
import { setMenuSelected } from '../../../toolkitRedux/toolkitReducer';
import { useNavigate } from 'react-router-dom';

import './layouts.css';

import bottomMenu from '../MenuList/FooterMenuList';

const Footer = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const menuSelected = useSelector((state) => state.toolkit.menuSelected);

	const handleMenuClick = (e) => {
		const btnId = e.target.id;
		dispatch(setMenuSelected(btnId));
		const target = btnId === 'home' ? '/' : `/pages/${btnId}`;
		navigate(target);
	};

	return (
		<footer>
			<div className={'footer'}>
				<div className={'menu'}>
					<Container>
						<Grid container spacing={2}>
							<Grid item xs={8} align="left" className={'font'}>
								{bottomMenu.map((option) => (
									<Button
										id={option.id}
										key={option.id}
										onClick={handleMenuClick}
										sx={{ mr: 3 }}
										className={menuSelected === option.id ? option.activeClass : option.inactiveClass}
									>
										{option.itemText}
									</Button>
								))}
							</Grid>
							<Grid item xs={4} align="right">
								<img className="logo" src={nbgLogo} height="40" />
								<img className="logo" src={visa} height="40" />
								<img className="logo" src={mc} height="40" />
								<img className="logo" src={ae} height="40" />
							</Grid>
						</Grid>
					</Container>
				</div>
				<div className="copyright">
					<Container>
						<Grid container spacing={2}>
							<Grid item xs={6} align="left">
								{'Copyright © Interpay Service Groupe LLC 2022 '}{' '}
								{new Date().getFullYear()}.
							</Grid>
							<Grid item xs={6} align="right">
								<Link href="/" className={'soc'}>
									<FacebookIcon />
								</Link>
								<Link href="/" className={'soc'}>
									<LinkedInIcon />
								</Link>
								<Link href="/" className={'soc'}>
									<InstagramIcon />
								</Link>
							</Grid>
						</Grid>
					</Container>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
