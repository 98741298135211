import React, { } from 'react';

import { 
            IconButton, 
            Grid, 
            Button, 
            Dialog, 
            DialogContent, 
            DialogContentText, 
            TextField,
            FormControl,
            Select,
            InputLabel,
            MenuItem,
       } from '@mui/material';

import { IconDotsVertical, IconPlus, IconSettings } from '@tabler/icons';



const MyAddresses = () => {

const [MyAddressesDialogOpen, setMyAddressesDialogOpen] = React.useState(false);

const MyAddressesClickOpen = () => {
    setMyAddressesDialogOpen(true);
};

const MyAddressesClose = () => {
    setMyAddressesDialogOpen(false);
};

	return (
		<div className='service-card'>
            <div className='service-card-header'>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <div className='service-card-header-title'>მისამართი</div>
                </Grid>
                <Grid item xs={6} align='right'>
                    <Button 
                        variant="outlined" 
                        startIcon={<IconPlus/>} 
                        size="small" 
                        className='button' 
                        onClick={MyAddressesClickOpen}
                    >
                        მისამართის დამატება
                    </Button>
                </Grid>
            </Grid>
            </div>
            <div className='service-card-body'>
                <table>
                    <tr className='table-header'>
                        <td width="" className="font">მისამართი</td>
                        <td width="" className="font">ქალაქი/დაბა</td>
                        <td width="" className="font" align='right'>
                            <IconButton aria-label="setting" size="small">
                                <IconSettings />
                            </IconButton>
                        </td>
                    </tr>
                    <tr>
                        <td>სულხან საბას 23/39 </td>
                        <td>ქუთაისი</td>
                        <td align='right'>
                            <IconButton aria-label="setting" color="primary" size="small">
                                <IconDotsVertical />
                            </IconButton>
                        </td>
                    </tr>
                    <tr>
                        <td>მაისურაძის 15/45 </td>
                        <td>ქუთაისი</td>
                        <td align='right'>
                            <IconButton aria-label="setting" color="primary" size="small">
                                <IconDotsVertical />
                            </IconButton>
                        </td>
                    </tr>
                    <tr>
                        <td>მაისურაძის 15/45 </td>
                        <td>ქუთაისი</td>
                        <td align='right'>
                            <IconButton aria-label="setting" color="primary" size="small">
                                <IconDotsVertical />
                            </IconButton>
                        </td>
                    </tr>
                </table>
                <Dialog
                    open={MyAddressesDialogOpen}
                    onClose={MyAddressesClose}
                    PaperProps={{ style: { borderRadius: 6, width:500 } }}
                >
                    <DialogContent>
                        <DialogContentText>
                            <div className={'service-card-dialog'}>
                                <div className="service-card-dialog-header">
                                    მისამარტის დამატება
                                </div>
                                <div className="service-card-dialog-body" align="center">
                                    
                                    <TextField 
                                        id="outlined-basic" 
                                        label="მისამარტი" 
                                        fullWidth 
                                        size="small"
                                        textField
                                        className="inputRounded" 
                                        variant="outlined" 
                                        sx={{ mb:3 }}
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">ქალაქი/დაბა</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            fullWidth 
                                            size="small"
                                            value="ქალაქი/დაბა"
                                            label="ქალაქი/დაბა"
                                            className="br-6"
                                        >
                                            <MenuItem>ქუთაისი</MenuItem>
                                            <MenuItem>თბილისი</MenuItem>
                                            <MenuItem>ბათუმი</MenuItem>
                                        </Select>
                                    </FormControl>
                                    
                                </div>
                                <div className="service-card-dialog-footer">
                                    <Button
                                        className={'button'}
                                        fullWidth
                                        startIcon={<IconPlus />}
                                    >
                                        მისამარტის დამატება
                                    </Button>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
	);
};

export default MyAddresses;
