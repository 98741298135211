import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useFetching } from '../../../hooks/useFetching';
import ApiService from '../../../API/ApiService';

import Container from '@mui/material/Container';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import HomeIcon from '@mui/icons-material/Home';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import IconButton from '@mui/material/IconButton';
import ReplyIcon from '@mui/icons-material/Reply';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import WarningAmberTwoToneIcon from '@mui/icons-material/WarningAmberTwoTone';
import { IconSend } from '@tabler/icons';

import { IconHome2 } from '@tabler/icons';

import {
	Button,
	Box,
	Grid,
	Dialog,
	TextField,
	DialogTitle,
	DialogContent,
	DialogActions,
	MenuItem,
} from '@mui/material';

import './UserMessages.css';
import { setUnreadedMessages } from './../../../toolkitRedux/toolkitReducer';
import Navlink from '../Breadcrumbs';

const UserMessages = (props) => {
	const token = useSelector((state) => state.toolkit.token);
	const userDetails = useSelector((state) => state.toolkit.userDetails);
	const unreadedMessages = useSelector(
		(state) => state.toolkit.unreadedMessages
	);
	const [messages, setMessages] = useState([]);
	const [messageRead, setMessageRead] = useState();
	const [newMessagePopup, setNewMessagePopup] = useState(false);
	const [newMessage, setNewMessage] = useState({
		category: 1,
		subject: '',
		body: '',
	});

	const dispatch = useDispatch();
	const direction = props?.direction;

	const [errNewMsgSubject, setErrNewMsgSubject] = useState(true);
	const [errNewMsgBody, setErrNewMsgBody] = useState(true);
	const [messageDirection, setMessageDirection] = useState(direction);
	const [categories, setCategories] = useState([]);
	const [listSelectedMessages, setListSelectedMessages] = useState([]);
	const [forceRerender, setForceRerender] = useState();
	const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
	const [readed, setReaded] = useState(false);

	let timer = null;

	// alert start
	const [notification, setNotification] = React.useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setNotification(false);
	};

	// alert end

	const [featching, isDataLoading, error] = useFetching(async (param) => {
		const response = await ApiService.userApi(param);
		return response;
	});

	const loadMessages = async () => {
		const request = {
			function: 'getUserMessages',
			token: token,
			pars: { direction: messageDirection },
		};
		const response = await featching(request);
		if (response.data.success === 'success') {
			setMessages(response.data.data);
		}
	};

	const loadCategories = async () => {
		const request = {
			function: 'listMsgCategories',
			token: token,
		};
		const response = await featching(request);
		if (response.data.success === 'success') {
			setCategories(response.data.data);
		}
	};

	const sendMessage = async () => {
		const request = {
			function: 'sendUserMessage',
			token: token,
			pars: newMessage,
		};
		// console.log('sendMessage:', request);
		const response = await featching(request);
		if (response.data.success === 'success') {
			loadMessages();
			setNewMessagePopup(false);
			setNotification(true);
		} else {
			alert(response.data.reason);
		}
	};

	const markReaded = async () => {
		const request = {
			function: 'markUserMessages',
			token: token,
			pars: { id: messageRead.id, direction: messageDirection },
		};
		const response = await featching(request);
		if (response.data.success === 'success') {
			// setMessages(response.data.data);
			// setMessageDirection('in');
			dispatch(setUnreadedMessages(unreadedMessages - 1));
		}
	};

	const deleteMessages = async () => {
		const request = {
			function: 'deleteUserMessages',
			token: token,
			pars: { listMessages: listSelectedMessages, direction: messageDirection },
		};
		const response = await featching(request);
		if (response.data.success === 'success') {
			setMessageRead(false);
			setMessages(response.data.data);
		}
	};

	const handleSelectAllMessages = () => {
		// console.log('listSelectedMessages.length', listSelectedMessages.length);
		// console.log('messages.length', messages.length)
		let tempMsg = [];
		if (listSelectedMessages.length < messages.length) {
			messages.map((option) => {
				tempMsg.push(option.id);
			});
		}
		setListSelectedMessages(tempMsg);
		// console.log('selectedMsgs', tempMsg);
	};

	const handleMessageSelect = (id) => {
		let tempMsg = listSelectedMessages;
		const idx = tempMsg.indexOf(id);
		if (idx !== -1) {
			tempMsg.splice(idx, 1);
		} else {
			tempMsg.push(id);
		}
		setListSelectedMessages(tempMsg);
		// console.log('selectedMsgs', tempMsg);
		setForceRerender(Math.random());
	};

	const handleDeleteMessages = () => {
		deleteMessages();
		setDeleteConfirmDialog(false);
	};

	const handleMessageRead = (option) => {
		clearTimeout(timer);
		setMessageRead(option);
		if (!option.readed) {
			timer = setTimeout(() => {
				clearTimeout(timer);
				setReaded(option.id);
			}, 3000);
		}
	};

	useEffect(() => {
		if (readed && readed === messageRead?.id) {
			let tempMsg = messages;
			const idx = tempMsg.indexOf(messageRead);
			if (idx > -1) {
				tempMsg[idx] = { ...messageRead, readed: 1 };
				setMessages(tempMsg);
				markReaded();
			}
		}
	}, [readed]);

	useEffect(() => {
		setListSelectedMessages([]);
		loadMessages();
		setMessageRead(false);
	}, [messageDirection]);

	useEffect(() => {
		loadCategories();
	}, []);

	const links = [
		{ title: 'საფოსტო ყუთი', link: '' },
	];

	return (
		<div>
			<Navlink links={links} />
			<Container value={forceRerender} className={'content'}>
				<div className={'message'}>
					<Grid container align="left" className={'header'}>
						<Grid item xs={0.3} align="left">
							<Checkbox
								edge="start"
								tabIndex={-1}
								disableRipple
								className={'checkbox'}
								checked={listSelectedMessages.length === messages.length}
								indeterminate={
									listSelectedMessages.length > 0 &&
									listSelectedMessages.length < messages.length
								}
								onClick={handleSelectAllMessages}
							/>
						</Grid>
						<Grid item xs={2.5}>
							<Box>
								<Tabs value={messageDirection}>
									<Tab
										onClick={() => setMessageDirection('in')}
										className={'but'}
										label="მიღებული"
										value="in"
									/>
									<Tab
										onClick={() => setMessageDirection('out')}
										className={'but'}
										label="გაგზავნილი"
										value="out"
									/>
								</Tabs>
							</Box>
						</Grid>
						<Grid item xs={0.7} align="left">
							<IconButton
								aria-label="delete"
								disabled={listSelectedMessages.length === 0}
								onClick={() => setDeleteConfirmDialog(true)}
							>
								<DeleteIcon />
							</IconButton>
						</Grid>
						<Grid item xs={8.4}>
							<Grid container>
								<Grid item xs={8} align="left">
									<TextField fullWidth className='inputRounded' size='small' label="შეტყობინნების ძებნა..." variant="outlined" />
								</Grid>
								<Grid item xs={4} align="right">
									<Button
										variant="contained"
										onClick={() => setNewMessagePopup(true)}
										startIcon={<AddIcon />}
										className={'button'}
										size='small'
									>
										ახალი წერილი
									</Button>
								</Grid>
							</Grid>

						</Grid>
					</Grid>
					<Grid container spacing={2} align="left" className={'content'}>
						<Grid item xs={3.5}>
							<div className={'list'}>
								{messages.map((option) => {
									const checkedMsg = listSelectedMessages.some(
										(e) => e === option.id
									);
									return (
										<Button
											// className={'inout'}
											key={option.id}
											className={
												option.id === messageRead.id ? 'inout-active' : 'inout'
											}
											// fullWidth
											value={checkedMsg}
											onClick={() => handleMessageRead(option)}
										>
											<ListItem className={'checkbox'}>
												<ListItemAvatar>
													<Checkbox
														checked={checkedMsg}
														onClick={() => handleMessageSelect(option.id)}
													/>
												</ListItemAvatar>
												<ListItemText>
													<Typography
														className={option.readed ? 'name' : 'name-bold'}
													// fontWeight={option.readed ? 'regular' : 'bold'}
													>
														{
															categories.find((e) => e.id === option.category)
																?.name
														}
														<br />
														{moment(option.create_time).format(
															'DD/MM/YYYY HH:mm'
														)}
													</Typography>
												</ListItemText>
											</ListItem>
										</Button>
									);
								})}
							</div>
						</Grid>
						<Grid item xs={8.3}>
							{messageRead && (
								<Box className={'message-box'}>
									<Grid container align="left">
										<Grid item xs={10}>
											<div className="title">{messageRead.subject}</div>
										</Grid>
										<Grid item xs={2} align="right">

										</Grid>
									</Grid>
									<div className={'text'}>{messageRead.body}</div>
									<div className={'message-box-send'}>
										<Grid container>
											<Grid item xs={11.3} align="left">
												<TextField fullWidth className='inputRounded' size='small' label="შეტყობინნება..." variant="outlined" />
											</Grid>
											<Grid item xs={0.7} align="right">
												<IconButton aria-label="delete" color='primary'>
													<IconSend />
												</IconButton>
											</Grid>
										</Grid>
									</div>
								</Box>
							)}
						</Grid>
					</Grid>
				</div>
				<Box display="flex" flexDirection="column">
					<Dialog
						PaperProps={{ style: { borderRadius: 6, width: 600 } }}
						open={newMessagePopup}
					// fullWidth
					// sx={{ height: '80vh', minWidth: '100%' }}
					>
						<DialogTitle className={'fs-16'}>შეტყობინების გაგზავნა</DialogTitle>
						<DialogContent>
							<div className="newMessage">
								<TextField
									// fullWidth
									label="თემა"
									defaultValue={1}
									select
									size="small"
									className="inputRounded"
									sx={{ mb: 3 }}
									onChange={(e) => {
										setNewMessage({ ...newMessage, category: e.target.value });
									}}
								>
									{categories.map((option) => (
										<MenuItem key={option.id} value={option.id}>
											{option.name}
										</MenuItem>
									))}
								</TextField>
								<TextField
									// fullWidth
									label="სათაური"
									error={errNewMsgSubject}
									size="small"
									className="inputRounded"
									onChange={(e) => {
										setNewMessage({ ...newMessage, subject: e.target.value });
										setErrNewMsgSubject(
											e.target.value.length > 0 ? false : true
										);
									}}
								/>
								<TextField
									// fullWidth
									label="შეტყობინება"
									multiline
									rows={5}
									size="small"
									className="inputRounded"
									error={errNewMsgBody}
									onChange={(e) => {
										setNewMessage({ ...newMessage, body: e.target.value });
										setErrNewMsgBody(e.target.value.length > 0 ? false : true);
									}}
								/>
							</div>
						</DialogContent>
						<DialogActions sx={{ p: 3 }}>
							<Button
								variant="contained"
								className={'button'}
								onClick={() => setNewMessagePopup(false)}
							>
								გაუქმება
							</Button>
							<Button
								variant="contained"
								className={'button'}
								endIcon={<SendIcon />}
								onClick={sendMessage}
								disabled={errNewMsgBody || errNewMsgSubject ? true : false}
							>
								გაგზავნა
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={deleteConfirmDialog}
						onClose={() => setDeleteConfirmDialog(false)}
						PaperProps={{ style: { borderRadius: 6 } }}
					>
						<DialogContent>
							<center>
								<WarningAmberTwoToneIcon className={'alert'} />
								<br></br>
								დარწმუნებული ხართ რომ გსურთ მონიშნული შეტყობინებ
								{listSelectedMessages.length > 1 && 'ებ'}ის წაშლა?
							</center>
						</DialogContent>
						<DialogActions sx={{ pl: 3, pr: 3, pb: 3 }} aling="center">
							<Button
								variant="contained"
								className={'button'}
								onClick={() => setDeleteConfirmDialog(false)}
							>
								უარყოფა
							</Button>
							<Button
								variant="contained"
								className={'button'}
								onClick={handleDeleteMessages}
							>
								თანხმობა
							</Button>
						</DialogActions>
					</Dialog>
				</Box>
			</Container>
			<Backdrop open={isDataLoading}>
				<CircularProgress size={100} />
			</Backdrop>
		</div>
	);
};

export default UserMessages;
