import React from 'react';

import { Grid, Button } from '@mui/material';
import { IconAlertTriangle } from '@tabler/icons';

const ProfileErrors = (props) => {
	const alertText = props.alertText;
	return (
		<div className={'profile-page'}>
			<div align="center" className="text-danger">
				<IconAlertTriangle width="100px" height="100px" />
			</div>
			{/* <div className='font text-primary fs-16' align='center'>აღნიშნული ელ–ფოსტა უკვე რეგისტრირებულია</div>
            <div className='font text-primary fs-16' align='center'>აღნიშნული ნომერი უკვე რეგისტრირებულია</div> */}
			<div className="font-bold text-primary fs-16" align="center">
				{alertText}
			</div>
			<p align="center" className="text-secondary">
				პრობლემის შემთხვევაში გთხოვთ დაგვიკავშირდეთ ბლა ბლა ბლა
			</p>
		</div>
	);
};

export default ProfileErrors;
