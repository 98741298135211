import React, { useState } from 'react';

import { List, Typography } from '@mui/material';

import MenuItem from './MenuItem';
import MenuCollapse from './MenuCollapse';

const MenuGroup = (props) => {
	const item = props.item;
	const currentMenu = props.current;
	const currentParent = props.pages[currentMenu]?.parent;
	const [openParent, setOpenParent] = useState(currentParent);
	const items = item?.map((menu) => {
		if (!menu.disabled) {
			switch (menu.type) {
				case 'collapse':
					return (
						<MenuCollapse
							key={menu.id}
							menuItem={menu}
							level={1}
							setPageContent={props.setPageContent}
							currentMenu={currentMenu}
							parent={currentParent}
							openParent={openParent}
							setOpenParent={setOpenParent}
						/>
					);
				case 'item':
					return (
						<MenuItem
							key={menu.id}
							menuItem={menu}
							level={1}
							setPageContent={props.setPageContent}
							currentMenu={currentMenu}
						/>
					);
				default:
					return (
						<Typography key={menu.id} variant="h6" color="error" align="center">
							Menu Items Error
						</Typography>
					);
			}
		}
	});

	return <List>{items}</List>;
};

export default MenuGroup;
