import React, { useState } from 'react';


const ProfileSecurity = () => {
	
	return (
		<div>
			დეაქტივაცია
		</div>
	);
};

export default ProfileSecurity;
