import React from 'react';

import { 
	IconButton, 
	Grid, 
	Button, 
	Dialog, 
	DialogContent, 
	DialogContentText, 
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Box,
	Tab,
} from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { IconRouter, IconSettings, IconDeviceTv, IconPhone, IconCheck } from '@tabler/icons';

const CurrentServices = () => {

	const [CurrentDialogOpen, setCurrentDialogOpen] = React.useState(false);
	
	const CurrentClickOpen = () => {
		setCurrentDialogOpen(true);
	};
	
	const CurrentClose = () => {
		setCurrentDialogOpen(false);
	};

	const [value, setValue] = React.useState('1');

	const handleChange = (event, newValue) => {
	  setValue(newValue);
	};
	return (
		<div className=''>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<div align='left' className='service-card-body-current-box'>
							<div className='service-card-body-current-box-logo'>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<div className='service-card-body-current-box-text font text-primary font-bold'>ინტერნეტი</div>
										<div className='service-card-body-current-box-text'><span className='font-bold-p'>აბონენტი:</span> ა.ლ.</div>
									</Grid>
									<Grid item xs={6} align='right'>
										<IconRouter width='40px' height='40px'/>
									</Grid>
								</Grid>	
							</div>
							
							<div className='service-card-body-current-box-text'><span className='font-bold-p'>პაკეტი:</span> სილვერ</div>
							<div className='service-card-body-current-box-text'><span className='font-bold-p'>ღირებულება:</span> 25 ₾</div>
							<div className='service-card-body-current-box-text'><span className='font-bold-p'>ბალანსი:</span> 25 ₾</div>
							<div className='service-card-body-current-box-text'><span className='font-bold-p'>სტატუსი:</span> აქტიური</div>
							<div className='service-card-body-current-box-address'><span className='font-bold-p'>მისამართი:</span> ქუთაისი, მაისურაძის მე4 მიკრო რაიონი, მესამე შესახვევი ნინა 23/33</div>
							<div className='service-card-body-current-box-button'>
								<Button 
									variant="outlined" 
									startIcon={<IconSettings />} 
									size="small" 
									className='button' 
									sx={{ mt:2 }}
									onClick={CurrentClickOpen}
									fullWidth
								>
									მართვა
								</Button>
							</div>
						</div>
					</Grid>
					<Grid item xs={6}>
						<div align='left' className='service-card-body-current-box'>
							<div className='service-card-body-current-box-logo'>
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<div className='service-card-body-current-box-text font-bold text-primary'>ტელეფონი</div>
										<div className='service-card-body-current-box-text'><span className='font-bold-p'>აბონენტი:</span> ა.ლ.</div>
									</Grid>
									<Grid item xs={6} align='right'>
										<IconPhone width='40px' height='40px'/>
									</Grid>
								</Grid>	
							</div>
							
							<div className='service-card-body-current-box-text'><span className='font-bold-p'>პაკეტი:</span> სილვერ</div>
							<div className='service-card-body-current-box-text'><span className='font-bold-p'>ღირებულება:</span> 25 ₾</div>
							<div className='service-card-body-current-box-text'><span className='font-bold-p'>ბალანსი:</span> 25 ₾</div>
							<div className='service-card-body-current-box-text'><span className='font-bold-p'>სტატუსი:</span> აქტიური</div>
							<div className='service-card-body-current-box-address'><span className='font-bold-p'>მისამართი:</span> ქუთაისი, მაისურაძის მე4 მიკრო რაიონი, მესამე შესახვევი ნინა 23/33</div>
							<div className='service-card-body-current-box-button'>
								<Button 
									variant="outlined" 
									startIcon={<IconSettings />} 
									size="small" 
									className='button' 
									sx={{ mt:2 }}
									onClick={CurrentClickOpen}
									fullWidth
								>
									მართვა
								</Button>
							</div>
						</div>
					</Grid>
				</Grid>

			<Dialog
				open={CurrentDialogOpen}
				onClose={CurrentClose}
				PaperProps={{ style: { borderRadius: 6, width:620 } }}
				maxWidth="md"
			>
				<DialogContent>
					<DialogContentText>
						<div className={'service-card-dialog'}>
							<div className="service-card-dialog-header">
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<div className='service-card-dialog-header-text font-bold'>ინტერნეტი</div>
										<div className='service-card-dialog-header-text2'><span className='font-bold-p'>აბონენტი:</span> ა.ლ.</div>
									</Grid>
									<Grid item xs={6} align='right'>
										<IconRouter width='40px' height='40px'/>
									</Grid>
								</Grid>	
							</div>
							

								<TabContext value={value} >
									<Box>
										<TabList onChange={handleChange} className='font' aria-label="lab API tabs example">
											<Tab label="მიმდინარე პაკეტი" value="1" sx={{ pl:0, pr:0, mr:3 }} />
											<Tab label="კრედიტის აღება" value="2" sx={{ pl:0, pr:0, mr:3 }} />
											<Tab label="პაკეტის ცვლილება" value="3" sx={{ pl:0, pr:0, mr:3 }}/>
											<Tab label="დაპაუზება" value="4" sx={{ pl:0, pr:0 }}/>
										</TabList>
									</Box>
									
									<TabPanel value="1" sx={{ pl:0, pr:0 }}>
										<List>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="სააბონენტო 1 ₾ - 30 დღე" />
												</ListItemButton>
											</ListItem>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="საუბარი შიდა ქსელში 0.01 ₾" />
												</ListItemButton>
											</ListItem>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="უფასო საუბარი შიდა ქსელში 1000 წთ" />
												</ListItemButton>
											</ListItem>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="სააბონენტო 1 ₾ - 30 დღე" />
												</ListItemButton>
											</ListItem>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="საუბარი შიდა ქსელში 0.01 ₾" />
												</ListItemButton>
											</ListItem>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="უფასო საუბარი შიდა ქსელში 1000 წთ" />
												</ListItemButton>
											</ListItem>
										</List>
									</TabPanel>

									<TabPanel value="2" sx={{ pl:0, pr:0 }}>
										<FormControl fullWidth size="small" className='br-6'>
											<InputLabel id="demo-simple-select-label">კრედიტს დღეები</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												
												label="კრედიტს დღეები"
											>
												<MenuItem>1 დღე</MenuItem>
												<MenuItem>2 დღე</MenuItem>
												<MenuItem>3 დღე</MenuItem>
											</Select>
										</FormControl>
										
										<div className='mt-5'><span className='font-bold-p'>დღეების რაოდენობა:</span> 1</div>
										<div><span className='font-bold-p'>ღირებულება: </span> 10 ₾</div>
									</TabPanel>
									
									<TabPanel value="3" sx={{ pl:0, pr:0 }}>
										<FormControl fullWidth size="small" className='br-6'>
											<InputLabel id="demo-simple-select-label">პაკეტი</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												
												label="პაკეტი"
											>
												<MenuItem>სილვერ</MenuItem>
												<MenuItem>გოლდ</MenuItem>
											</Select>
										</FormControl>

								
										<List sx={{ mt:2 }}>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="სააბონენტო 1 ₾ - 30 დღე" />
												</ListItemButton>
											</ListItem>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="საუბარი შიდა ქსელში 0.01 ₾" />
												</ListItemButton>
											</ListItem>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="უფასო საუბარი შიდა ქსელში 1000 წთ" />
												</ListItemButton>
											</ListItem>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="სააბონენტო 1 ₾ - 30 დღე" />
												</ListItemButton>
											</ListItem>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="საუბარი შიდა ქსელში 0.01 ₾" />
												</ListItemButton>
											</ListItem>
											<ListItem disablePadding>
												<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
													<ListItemIcon>
														<IconCheck className='text-primary' width='20px'/>
													</ListItemIcon>
													<ListItemText primary="უფასო საუბარი შიდა ქსელში 1000 წთ" />
												</ListItemButton>
											</ListItem>
										</List>
									</TabPanel>

									<TabPanel value="4" sx={{ pl:0, pr:0 }}>
										<FormControl fullWidth size="small" className='br-6'>
											<InputLabel id="demo-simple-select-label">დრო</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												
												label="დრო"
											>
												<MenuItem>1 თვე</MenuItem>
												<MenuItem>2 თვე</MenuItem>
												<MenuItem>3 თვე</MenuItem>
												<MenuItem>4 თვე</MenuItem>
												<MenuItem>5 თვე</MenuItem>
												<MenuItem>6 თვე</MenuItem>
											</Select>
										</FormControl>
										
										<div className='mt-5'><span className='font-bold-p'>დღეების რაოდენობა:</span> 1</div>
										<div><span className='font-bold-p'>ღირებულება: </span> 10 ₾</div>
									</TabPanel>
								</TabContext>
								
								
							
							<div className="service-card-dialog-footer">
								<Button 
									variant="outlined" 
									endIcon={<IconCheck />} 
									size="small" 
									className='button' 
									onClick={CurrentClickOpen}
									fullWidth
								>
									ცვლილების დამახსოვრება
								</Button>
							</div>
						</div>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default CurrentServices;