import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { setDarkMode } from '../../toolkitRedux/toolkitReducer';

export default function DarkMode() {
	const [cookies, setCookie] = useCookies('darkMode');
	const darkMode = useSelector(state => state.toolkit.darkMode);
	const dispatch = useDispatch();

	useEffect(() => {
		setCookie('darkMode', darkMode);
	},[darkMode]);

	useEffect(() => {
		// console.log('Fired useeffect: ',cookies.darkMode);
		if (cookies.darkMode === "true") 
		{
			dispatch(setDarkMode(true));
		} else {
			dispatch(setDarkMode(false));
		}
	},[]);
}