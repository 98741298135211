import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const MenuItem = (props) => {
	const menuItem = props.menuItem;
	const navigate = useNavigate();
	const submenu = props.currentMenu;

	let itemTarget = '_self';
	if (menuItem.target) {
		itemTarget = '_blank';
	}

	const Icon = menuItem.icon ? menuItem.icon : undefined;
	const itemIcon = menuItem?.icon ? (
		<Icon stroke={1.5} size="1.3rem" />
	) : (
		<FiberManualRecordIcon
			sx={{
				width: 6,
				height: 6,
			}}
		/>
	);

	const handleOnClick = () => {
		if (menuItem.target) {
			navigate(menuItem.target);
		}
	};

	return (
		<ListItemButton
			// {...listItemProps}
			sx={{
				// borderRadius: `${customization.borderRadius}px`,
				mb: 0.5,
				alignItems: 'flex-start',
				// backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
				// py: level > 1 ? 1 : 1.25,
				// pl: `${level * 24}px`
			}}
			onClick={handleOnClick}
			selected={submenu === menuItem.id}
		>
			<ListItemIcon sx={{ my: 'auto', minWidth: !menuItem?.icon ? 18 : 36 }}>
				<span sx={{ mt: 4 }}>{itemIcon}</span>
			</ListItemIcon>
			<ListItemText>{menuItem.title}</ListItemText>
		</ListItemButton>
	);
};

export default MenuItem;
