import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import validator from '../../../validator';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { Snackbar, Alert } from '@mui/material';

import UserLoad from '../../../API/UserLoad';

const ProfileEmailChange = (props) => {
	const userDetails = useSelector((state) => state.toolkit.userDetails);
	// const userDetails = JSON.parse(localStorage.getItem('userDetails'));
	const [newValues, setNewValues] = useState('');
	const [isValid, setIsValid] = useState(false);

	const token = useSelector((state) => state.toolkit.token);
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertText, setAlertText] = useState('');

	const handleAlertClose = () => {
		setAlertOpen(false);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && isValid) {
			handleSubmit();
		}
	};

	const alertAction = (
		<React.Fragment>
			<Button color="secondary" size="small" onClick={handleAlertClose}>
				{' '}
			</Button>
			<IconButton size="small" aria-label="close" onClick={handleAlertClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	const handleSubmit = async () => {
		const pars = { name: 'email', value: newValues.email };
		const response = await UserLoad.verifyOnDuplicates(token, pars);
		console.log(response);
		if (response.success == 'success') {
			if (!response.exists) {
				props.setMessageText(
					`კოდი გამოგზავნილია ელ.ფოსტაზე: ${newValues.email}`
				);
				props.setNewValues(newValues);
				props.setPage(2);
			} else {
				setAlertText('მითითებული ელ.ფოსტა უკვე რეგისტრირებულია სისტემაში.');
				setAlertOpen(true);
			}
		}
	};

	const changeValue = (e) => {
		if (validator.isEmail(e.target.value)) {
			setIsValid(true);
			setNewValues({ email: e.target.value });
		} else {
			setIsValid(false);
		}
	};

	return (
		<div className="phone-mail-change-box">
			{userDetails.email && (
				<div className="phone-mail-change-box-title">
					თქვენი ელფოსტა:{' '}
					<span className="font-bold-p">{userDetails.email}</span>
				</div>
			)}
			<div>
				<TextField
					fullWidth
					className="inputRounded"
					size="small"
					label="ახალი ელფოსტა"
					variant="outlined"
					autoFocus
					onChange={changeValue}
					onKeyPress={handleKeyPress}
				/>
			</div>
			<div>
				<Button
					variant="contained"
					className="button"
					sx={{ mt: 3 }}
					endIcon={<NavigateNextIcon />}
					fullWidth
					onClick={handleSubmit}
					disabled={!isValid}
				>
					გაგრძელება
				</Button>
			</div>
			<Snackbar
				open={alertOpen}
				autoHideDuration={6000}
				onClose={handleAlertClose}
				action={alertAction}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				<Alert severity="error">{alertText}</Alert>
			</Snackbar>
		</div>
	);
};

export default ProfileEmailChange;
