import React from 'react';

import { Grid, 
	     Dialog, 
		 DialogContent, 
		 DialogContentText, 
		 Button,  
		 Select, 
		 FormControl, 
		 InputLabel,
		 MenuItem,
		 List,
		 ListItem,
		 ListItemButton,
		 ListItemIcon,
		 ListItemText
		} from '@mui/material';


import { IconCreditCard, IconShoppingCartPlus, IconPhone, IconCheck } from '@tabler/icons';

import InfoIcon from '@mui/icons-material/Info';



const MyOrders = () =>  {
	const [moreDialogOpen, setMoreDialogOpen] = React.useState(false);

	const handleClickOpen = () => {
		setMoreDialogOpen(true);
	};

	const handleClose = () => {
		setMoreDialogOpen(false);
	};

	return (
		<div>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<div className={'pay-box'}>
						<div className="box">
							<div className="box-header">
								<Grid container spacing={2} align="left">
									<Grid item xs={6.8}>
										<div className="fs-14 text-primary font-bold"> ტელეფონი</div>
										<div className="category">აბონენტი: ა. ქ. </div>
									</Grid>
									<Grid item xs={5.2} align='right'>
										<IconPhone width='40px' height='40px'/>
									</Grid>
								</Grid>
							</div>
							<div className="box-body">
								<FormControl fullWidth size="small">
									<InputLabel id="demo-simple-select-label">
										გადახდის მეთოდი
									</InputLabel>
									<Select
										labelId="demo-select-small"
										id="demo-select-small"
										label="გადახდის მეთოდი"
									>
										<MenuItem>ქართული ბარათით</MenuItem>
										<MenuItem>უცხოური ბარათით</MenuItem>
									</Select>
								</FormControl>
								<br></br>
								<br></br>

								<div className="text ">გადასახდელი თანხა: 200 ₾ </div>
								<div className="text ">საკომისიო: 30 ₾ </div>
								<div className="text ">
									სულ გადასახდელი თანხა: 230 ₾
								</div>
								<Button
									variant="text"
									className=" fs-14 text-primary"
									onClick={handleClickOpen}
									sx={{ p: 0 }}
									endIcon={<InfoIcon />}
								>
									დეტალები
								</Button>
							</div>
							<div className="box-footer">
								<Grid container spacing={2}>
									<Grid item xs={7.2}>
										<Button
											variant="text"
											sx={{ pl: 0, pr: 0 }}
											className="font"
											fullWidth
											startIcon={<IconShoppingCartPlus />}
										>
											გადახდებში დამატება
										</Button>
									</Grid>
									<Grid item xs={4.8}>
										<Button
											className={'button'}
											fullWidth
											endIcon={<IconCreditCard />}
										>
											გადახდა
										</Button>
									</Grid>
								</Grid>
							</div>
						</div>
					</div>

				</Grid>

			</Grid>

			<Dialog
				open={moreDialogOpen}
				onClose={handleClose}
				PaperProps={{ style: { borderRadius: 6, width:620 } }}
				maxWidth="md"
			>
				<DialogContent>
					<DialogContentText>
						<div className={'pay-box-dialog'}>
							<div className="header">
								<Grid container spacing={2} align="left">
									<Grid item xs={8}>
										<div className="title"> ტელეკომ 1</div>
										<div className="category">აბონენტი: ა. ლ. </div>
									</Grid>
									<Grid item xs={4} align='right'>
									<IconPhone width='40px' height='40px'/>
									</Grid>
								</Grid>
							</div>
							<div className="body" align="center">
								<List>
									<ListItem disablePadding>
										<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
											<ListItemIcon>
												<IconCheck className='text-primary' width='20px'/>
											</ListItemIcon>
											<ListItemText primary="სააბონენტო 1 ₾ - 30 დღე" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
											<ListItemIcon>
												<IconCheck className='text-primary' width='20px'/>
											</ListItemIcon>
											<ListItemText primary="საუბარი შიდა ქსელში 0.01 ₾" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
											<ListItemIcon>
												<IconCheck className='text-primary' width='20px'/>
											</ListItemIcon>
											<ListItemText primary="უფასო საუბარი შიდა ქსელში 1000 წთ" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
											<ListItemIcon>
												<IconCheck className='text-primary' width='20px'/>
											</ListItemIcon>
											<ListItemText primary="ფიქსირებული ქსელი 0.06 ₾" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
											<ListItemIcon>
												<IconCheck className='text-primary' width='20px'/>
											</ListItemIcon>
											<ListItemText primary="მობილური ქსელი 0.10 ₾" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
											<ListItemIcon>
												<IconCheck className='text-primary' width='20px'/>
											</ListItemIcon>
											<ListItemText primary="ბონუსი შემომავალ წუთზე 0 ₾" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
											<ListItemIcon>
												<IconCheck className='text-primary' width='20px'/>
											</ListItemIcon>
											<ListItemText primary="SMS 0.06 ₾" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding>
										<ListItemButton sx={{ pl:1, pt:0, pb:0 }}>
											<ListItemIcon>
												<IconCheck className='text-primary' width='20px'/>
											</ListItemIcon>
											<ListItemText primary="უფასო SMS დღეში 50" />
										</ListItemButton>
									</ListItem>
								</List>
							</div>
							<div className="pay-box-dialog-footer">
								<Grid container spacing={2}>
									<Grid item xs={7.2}>
										<Button
											variant="text"
											sx={{ pl: 0, pr: 0 }}
											className="font"
											fullWidth
											startIcon={<IconShoppingCartPlus />}
										>
											გადახდებში დამატება
										</Button>
									</Grid>
									<Grid item xs={4.8}>
										<Button
											className={'button'}
											fullWidth
											endIcon={<IconCreditCard />}
										>
											გადახდა
										</Button>
									</Grid>
								</Grid>
							</div>
						</div>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default MyOrders;