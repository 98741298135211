import React from 'react';

import {
	Collapse,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';

import MenuItem from './MenuItem';

const MenuCollapse = (props) => {

	const menuItem = props.menuItem;

	const handleClick = () => {
		const parent = (props.openParent === menuItem.id) ? '' : menuItem.id;
		props.setOpenParent(parent);
	};

	const Icon = menuItem.icon;
	const menuIcon = menuItem.icon ? (
		<Icon
			strokeWidth={1.5}
			size="1.3rem"
			style={{ marginTop: 'auto', marginBottom: 'auto' }}
		/>
	) : (
		<FiberManualRecordIcon
			sx={{
				width: props.openParent === menuItem.id ? 8 : 6,
				height: props.openParent === menuItem.id ? 8 : 6,
			}}
		/>
	);

	return (
		<>
			<ListItemButton
				sx={{
					mb: 0.5,
					alignItems: 'flex-start',
				}}
				selected={props.openParent === menuItem.id}
				onClick={handleClick}
			>
				<ListItemIcon sx={{ my: 'auto', minWidth: !menuItem.icon ? 18 : 36 }}>
					{menuIcon}
				</ListItemIcon>
				<ListItemText
					primary={
						<Typography
							variant={'body1'}
							color="inherit"
							sx={{ my: 'auto' }}
						>
							{menuItem.title}
						</Typography>
					}
				/>
				{props.openParent === menuItem.id ? (
					<IconChevronUp
						stroke={1.5}
						size="1rem"
						style={{ marginTop: 'auto', marginBottom: 'auto' }}
					/>
				) : (
					<IconChevronDown
						stroke={1.5}
						size="1rem"
						style={{ marginTop: 'auto', marginBottom: 'auto' }}
					/>
				)}
			</ListItemButton>
			<Collapse
				in={
					props.openParent === menuItem.id
				}
			>
				<List>
					{menuItem.children.map((cmenuItem) => {
						return (
							<MenuItem
								key={cmenuItem.id}
								menuItem={cmenuItem}
								setPageContent={props.setPageContent}
								currentMenu={props.currentMenu}
							/>
						);
					})}
				</List>
			</Collapse>
		</>
	);
};

export default MenuCollapse;
