import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ProfileImage = (props) => {
	const userDetails = useSelector((state) => state.toolkit.userDetails);
	const swidth = props.picsize * 1.33;
	let profileImage = '';
	if (userDetails) {
		const randId = Date.now();
		profileImage = userDetails?.avatar ? userDetails.avatar : 'https://ipsg.ge/p/images/ids/' + userDetails.uID + '_face.jpg';
		profileImage = `${profileImage}?${randId}`;
	}
	return (
		<div
			style={{
				borderRadius: '50%',
				backgroundImage: `url(${profileImage})`,
				width: `${props.picsize}px`,
				height: `${props.picsize}px`,
				backgroundSize: `${swidth}px`,
				backgroundPosition: 'center',
				border: '1px solid #6381a8',
			}}
		></div>
	);
};

export default ProfileImage;
