import axios from "axios";

export default class ApiService {
	static async userRegister(pars) {
		const obj = JSON.stringify(pars);
		const response = await axios.post(
			"https://ipsg.ge/p/userRegister.php",
			obj
		);
		return response;
	}

	static async unrApi(pars) {
		const obj = JSON.stringify(pars);
		const response = await axios.post("https://ipsg.ge/p/unrApi.php", obj);
		return response;
	}

	static async userApi(pars) {
		const obj = JSON.stringify(pars);
		const response = await axios.post("https://ipsg.ge/p/userApi.php", obj);
		return response;
	}

	static async userLogin(pars) {
		const obj = JSON.stringify(pars);
		const response = await axios.post("https://ipsg.ge/p/userLogin.php", obj);
		return response;
	}

	static async uploadFile(obj) {
		const response = await axios.post("https://ipsg.ge/p/uploadFile.php", obj);
		return response;
	}

	static async loadPage(pars) {
		const obj = JSON.stringify(pars);
		const response = await axios.post("https://ipsg.ge/p/pages.php", obj);
		return response;
	}
}
