import {
	IconUserCircle,
	IconDeviceDesktop,
	IconPhone,
	IconMail,
	IconMapPin,
	IconBan,
} from "@tabler/icons";

import ProfileSessionHistory from "./ProfileSessionHistory";
import ProfileValueChange from "./ProfileValueChange";
import ProfileErrors from "./ProfileErrors";
import ProfileChangeVerify from "./ProfileChangeVerify";
import ProfileData from "./ProfileData";
import ProfileDeactivation from "./ProfileDeactivation";

const icons = {
	IconUserCircle,
	IconDeviceDesktop,
	IconPhone,
	IconMail,
	IconMapPin,
	IconBan,
};

const menuItems = [
	{
		id: "data",
		title: "პირადი მონაცემები",
		type: "item",
		icon: icons.IconUserCircle,
		target: "/profile/data",
	},
	{
		id: "sessionhistory",
		title: "IP მისამართების ისტორია",
		type: "item",
		icon: icons.IconDeviceDesktop,
		target: "/profile/sessionhistory",
	},
	{
		id: "updatephone",
		title: "ტელეფონის ცვლილება",
		type: "item",
		icon: icons.IconPhone,
		target: "/profile/updatephone",
	},
	{
		id: "updateemail",
		title: "ელფოსტის ცვლილება",
		type: "item",
		icon: icons.IconMail,
		target: "/profile/updateemail",
	},
	{
		id: "changeaddress",
		title: "მისამართის ცვლილება",
		type: "item",
		icon: icons.IconMapPin,
		target: "/profile/updateaddress",
		onClick: <ProfileValueChange action="address" />,
		disabled: true,
	},
	{
		id: "profileChangeVerify",
		title: "ვერიფიკაციის ტიპი",
		type: "item",
		icon: icons.IconMapPin,
		onClick: <ProfileChangeVerify action="address" />,
		disabled: true,
	},
	{
		id: "profileErrors",
		title: "შეცდომის გვერდი",
		type: "item",
		icon: icons.IconMapPin,
		onClick: <ProfileErrors action="address" />,
		disabled: true,
	},
	{
		id: "deactivate",
		title: "ანგარიშის გაუქმება",
		type: "item",
		icon: icons.IconBan,
		target: "/profile/deactivate",
		onClick: <ProfileDeactivation />,
		disabled: true,
	},
];

const pages = {
	data: {
		title: "პირადი მონაცემები",
		target: <ProfileData />,
	},
	sessionhistory: {
		title: "IP მისამართების ისტორია",
		target: <ProfileSessionHistory />,
	},
	updatephone: {
		title: "ტელეფონის ცვლილება",
		target: <ProfileValueChange action="phone" />,
	},
	updateemail: {
		title: "ელფოსტის ცვლილება",
		target: <ProfileValueChange action="email" />,
	},
	addphone: {
		title: "ტელეფონის დამატება",
		target: <ProfileValueChange action="addphone" />,
	},
	addemail: {
		title: "ელფოსტის დამატება",
		target: <ProfileValueChange action="addemail" />,
	},
};

export { menuItems, pages };
