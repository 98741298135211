import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './PaymentOrderShow.css';
import logo2 from '../../../assets/images/iplog.png';
import stamp from '../../../assets/images/stamp.png';

const PaymentOrderShow = (prop) => {

	return (
		<div className='payment-order'>
			<div className='payment-order-header'>
				<div className='payment-order-header-info'>
					<h2 className='font-bold'>საგადასახადო დავალება</h2>
					<h3 className='font-bold mt'>ID 1334534667347</h3>
				</div>
				<div className='payment-order-header-logo'>
					<img
						src={logo2}
						height="66"
			    	/>
					<p>თარიღი: 06/10/2022</p>
				</div>
			</div>


			<div className='payment-order-body'>
				<h3 className='font-bold'>ტრანზაქციის დეტალები</h3>

				<table>
					<tr className="table-header">
						<td width="" className="font">
							N
						</td>
						<td width="" className="font">
							კომპანია
						</td>
						<td width="" className="font">
							სერვისი
						</td>
						<td width="" className="font">
							დეტალები
						</td>
						<td align="center" width="8%" className="font">
							პაკეტი
						</td>
						<td align="center" width="8%" className="font">
							ღირებულება
						</td>
						<td align="center" width="8%" className="font">
							თანხა
						</td>
					</tr>
					<tr>
						<td>1</td>
						<td>პარტნიორი ოპერატორი 1 </td>
						<td>ინტერნეტი </td>
						<td>ქუთაისი, ჯავახიშვილის 4 </td>
						<td align="center">Gold</td>
						<td align="center">50 ₾</td>
						<td align="center">50 ₾</td>
					</tr>
					<tr>
						<td>2</td>
						<td>პარტნიორი ოპერატორი 1 </td>
						<td>ტელევიზია </td>
						<td>წყალტუბო, დადიანის 3 </td>
						<td align="center">Silver</td>
						<td align="center">30 ₾</td>
						<td align="center">50 ₾</td>
					</tr>
					<tr>
						<td>3</td>
						<td>პარტნიორი ოპერატორი 1 </td>
						<td>ტელეფონი </td>
						<td>წყალტუბო, ერისთავის 3 </td>
						<td align="center">Gold</td>
						<td align="center">50 ₾</td>
						<td align="center">50 ₾</td>
					</tr>
					<tr>
						<td>4</td>
						<td>პარტნიორი ოპერატორი 2 </td>
						<td>ინტერნეტი </td>
						<td>ქუთაისი გამსახურდიას 3</td>
						<td align="center">Silver</td>
						<td align="center">50 ₾</td>
						<td align="center">50 ₾</td>
					</tr>
				</table>
			</div>

			<div className='payment-order-pay'>
				<div className='payment-order-pay-p'>სულ გადახდილი თანხა:</div>
				<div className='payment-order-pay-span'>200 ₾</div>

				<div className='payment-order-pay-p'>ბარათის ბოლო 4 ციფრი:</div>
				<div className='payment-order-pay-span'>**** **** **** 2542</div>

				<div className='payment-order-pay-p'>ტრანზაქცია:</div>
				<div className='payment-order-pay-span'>წარმატებული</div>
			</div>

			<div className='payment-order-footer'>
				<div className='payment-order-footer-receiver'>
					<h3 className='font-bold'>მიმღები</h3>
					<p>შპს ინტერფეი სერვის ჯგუფი</p>
					{/* <p>ს/კ: 412 766 430</p> */}
					<p>ტელეფონი: (032) 3 100 200</p>
					<p>ელ-ფოსტა: info@interpay.ge</p>
					{/* <p>მისამართი: საქართველი, ქუთაისი, 4600</p>
					<p>ზ. გამსახურდიას I შეს. N4/15</p> */}
				</div>
				<div className='payment-order-footer-sender'>
					<h3 className='font-bold'>გადამხდელი</h3>
					<p>მომხმარებელი: ავთანდილ ქარქაშაძე</p>
					<p>ანგარიშის ნომერი: 60001123921</p>
					<p>სტატუსი: აქტიური</p>
				</div>
			</div>

			<img
			className='stamp'
						src={stamp}
						height="200"
			    	/>

			

			
		</div>
	);
};

export default PaymentOrderShow;
